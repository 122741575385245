import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth';


const Logout = props => {

    const dispatch = useDispatch();
    const historyObj = useHistory();
    
    useEffect(()=>{
        dispatch(logout());
        historyObj.replace('/');
    },[dispatch , historyObj]);

    return(
        <div></div>
    );
}

export default Logout;