import React,{ useState , useEffect }  from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { TextInputWithLable , FileInput, SelectInput, FormSubmitBtn } from '../formComponents/formComponents';
import { createSubCategory } from '../../redux/actions/subCategories';
import { loadCategories } from '../../redux/actions/categories';
import { SUBCATEGORY_RESET_ACTION } from '../../redux/actions/actions_types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from '../loader/Loader';
import getFileMimeType from '../../helpers/getFileMimeType';
import toastHandler from '../ToastNotification/toastHandler'
import classes from './CommonStyle.module.css';

const CreateSubCategory = props => {

    const[allCategories , setAllCategories] = useState([]);
    const[formInvalid , setFormInvalid] = useState(true);
    const[file , setFile] = useState(null);
    const[loading , setLoading] = useState(true);
    
    // ---- Reset local components states
    const resetStates = () => {
        setFormInvalid(true);
        setFile(null);
    }

    // ---- Handle file check its mime type -------
    const fileHandler = event => {
        if(event.target.files[0]){  
            getFileMimeType(event.target.files[0], function(mime) {
                if(mime === 'image/jpeg' || mime === 'image/png'){
                    setFile(event.target.files[0]);
                    setFormInvalid(false)
                }else{
                    resetStates();
                    toastHandler('File type is Invalid Only jpeg , png supported' , 'error');
                }
            });
        }else{
            resetStates();
        }
    }

    // ---- Formik schema validation for createSubCategory-------
    const createSubCategorySchema = Yup.object().shape({
        title : Yup.string().required('Title is Required!').min(3 ,'Title must be of 3 or more characters') 
                                                            .max(20 ,'Title must be of 20 or less characters'), 
        categoryId : Yup.object().shape({value: Yup.number().required('Category is required')}),
    })
    
    // ---- Form initial values -------
    const initialValues = { 
        title : '' ,
    }
    
    // ---- Form initial values -------
    const formConfiguration = {
        title : { type:'text', name:'title', lable : 'Title'}, 
        image : { name:"image",accept:"image/png, image/jpeg" ,lable:"Image"},
        categoryId : {name:'categoryId'},
    }

    // ---- Form submit handler-------
    const dispatch = useDispatch();
    const createSubCategoryHandler = values => {
        setLoading(true);
        let formData = new FormData();
        formData.append('title' ,values.title);
        formData.append('categoryId' ,values.categoryId.value);
        formData.append('image',file);
        dispatch(createSubCategory(formData));
    }

    // ---- Load categories for Select items
    useEffect(()=>{
        dispatch(loadCategories()); 
    },[dispatch]);
    
    // ---- Set categories to local state for select
    const categoriesStateObj = useSelector( rootState => rootState.categoriesState );
    useEffect(()=>{
      if(categoriesStateObj.success){
        if(categoriesStateObj.categories){
            setAllCategories(categoriesStateObj.categories);
        }
        setLoading(false);
      }  
    },[categoriesStateObj]);

   // ---- Goback when subcategory create-------
    const history = useHistory();
    const subCategoryStateObj = useSelector( rootState => rootState.subCategoriesState );
    useEffect(()=>{
      if(subCategoryStateObj.action){
        dispatch({
            type : SUBCATEGORY_RESET_ACTION ,
        });
        setLoading(false);
        history.goBack();
      }  
    },[subCategoryStateObj,history, dispatch]);

    if(loading){
        return <Loader />
    }
    
    return(
        <div className={classes.ParentDiv} >
            <h1 className="large text-primary">Create SubCategory</h1>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={createSubCategorySchema}
                    onSubmit={values => createSubCategoryHandler(values)}
                >
                    {(formik) => {
                        
                        const { isValid, dirty , setFieldValue} = formik;
                        return (
                            
                            <Form className={classes.Form} >
                                <TextInputWithLable 
                                    {...formConfiguration['title']}
                                />


                                <SelectInput
                                    setFieldValue={setFieldValue}
                                    lable='Category'
                                    {...formConfiguration['categoryId']}
                                    children = {allCategories.length    ? allCategories.map(category => {
                                                                                            return {value:category.id , label:category.name}
                                                                                        })
                                                                        : null }
                                >
                                    
                                </SelectInput>
                                
                                <FileInput 
                                   {...formConfiguration['image']}
                                   onChange={fileHandler}
                                />
                                
                                <FormSubmitBtn 
                                    btnTitle="Create" 
                                    dirty={dirty}
                                    isValid={isValid}
                                    formInvalid={formInvalid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
        
    );

}

export default CreateSubCategory;