import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { useParams ,useHistory } from 'react-router-dom';
import { getCategoryById , deleteCategory  } from '../../redux/actions/categories';
import { CATEGORY_RESET_ACTION } from '../../redux/actions/actions_types';
import { BackButton , CustomIconButton , DeleteButton } from '../actionButtons/actionButtons'
import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';

import classes from './CommonStyle.module.css';

export default function ViewCategory( props ) {
    
    const[category , setCategory] = useState();
    const[loading , setLoading] = useState(true);
    const[isDeleted , setIsDeleted] = useState(false);
    
    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }

    // Load Category data by id
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getCategoryById(id)); 
    },[dispatch , id]);
    
    // Set Category data from redux to local state
    const categoriesStateObj = useSelector( rootState => rootState.categoriesState );
    useEffect(()=>{
      if(categoriesStateObj.action){
        dispatch({
          type : CATEGORY_RESET_ACTION,
        }); 
        setCategory(categoriesStateObj.category);
        setLoading(false);
      }  
    },[categoriesStateObj,dispatch]);

    // ---- Delete category handler ----- 
    const deleteCategoryHandler = id => {
      Confirm('Are you sure to delete this Category?' ,  result => {
        if(result){
          setLoading(true);
          setIsDeleted(true);
          dispatch(deleteCategory(id)); 
        }
      })
    }

    // ---- Check either category delete Or Not ------

    useEffect(()=>{
      if(categoriesStateObj.action && isDeleted){
        dispatch({
          type : CATEGORY_RESET_ACTION
        });
        setLoading(false);
        history.goBack();
      }  
    },[categoriesStateObj , dispatch , history , isDeleted]);


  if(loading){
    return <Loader />
  }

  return (
    <div>
        <div>
          <h1 style={{marginTop:'2%'}} >{category.name}</h1>
          <div className={classes.LoadedImage}>
              <img 
                src={category.image}
                alt='category'
              />
          </div>
         


          <br></br>
          <br></br>
          <BackButton />
          <CustomIconButton 
              link = {`/dashboard/categories/edit/${category.id}`}
              title = "Edit Category"
              icon = "EditIcon"
          />
          <DeleteButton 
            title = "Delete Category"
            handler = {() => deleteCategoryHandler(category.id)}
          />

          
          
        </div>
    </div>
  );
}