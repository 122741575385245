import React ,{ useEffect, useState } from 'react';
import { useDispatch  , useSelector  } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TextInput , FormSubmitBtn } from './../../formComponents/formComponents';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { verifyEmail } from '../../../redux/actions/auth';
import { RESET_OTP_SENT } from '../../../redux/actions/actions_types';
import loginPageImgSrc  from  '../../../assets/images/tailor1.jpg';

import classes from './Common.module.css';
import Loader from '../../loader/Loader';

const VerifyEmail = props => {

    const [loading , setLoading] = useState(false);

    const dispatch = useDispatch();

    // ---- formik schema for verifyEmail
    const verifyEmailSchema = Yup.object().shape({
        email : Yup.string().email('Enter valid email').required('Email is Required!') , 
    })

    // ---- form initial values
    const initialValues = { 
        email : '' , 
    }

    // ---- form configurations
    const formConfiguration = {
        email : {type:'email', name:'email',lable : 'Email'} , 
    }

    // ---- form submit handler
    const verifyEmailHandler = formData => {
        setLoading(true);
        dispatch(verifyEmail(formData.email));
    }

    // ---- Navigate to VerifyOTP Screen when OTP done
    const authStateObj = useSelector( rootState => rootState.authState );
    const history = useHistory();
    useEffect(() => {
        if(authStateObj.otpSent){
            dispatch({
                type : RESET_OTP_SENT
            })
            setLoading(false);
            history.push('/verifyOTP');
        }
        
    },[dispatch , authStateObj , history]);
    
    return(
        <div className={classes.parentDiv}>

            <div className={classes.sideDiv}>
                <img src={loginPageImgSrc} alt="loginPage" />
            </div>

            <div className={classes.FormDiv} >
                <h1 className="large text-primary"> Verify Email</h1>
                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={verifyEmailSchema}
                        onSubmit={values => verifyEmailHandler(values)}
                    >
                        {(formik) => {
                            
                            const { isValid, dirty} = formik;
                            return (
                                
                                <Form className={classes.Form} >

                                    {
                                        loading ? <Loader />
                                                :   <TextInput 
                                                        {...formConfiguration['email']}
                                                    />
                                    }
                                    
                                    
                                  

                                    <br/>
                                    <FormSubmitBtn 
                                        btnTitle="Verify Email" 
                                        dirty={dirty}
                                        isValid={isValid}
                                    />

                                   
                                </Form>
                                
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
        
    );

}

export default VerifyEmail;