import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { loadSubCategories , deleteSubCategory  } from '../../redux/actions/subCategories';
import { CustomIconButton , DeleteButton } from '../actionButtons/actionButtons';

import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';

const useStyles = makeStyles({
  container: {
    display:"flex",
    justifyContent:'center',
    flexWrap:"wrap",
    width: '80%',
    margin : 'auto'
  },
  root: {
    padding : '1%',
    backgroundColor: 'white',
    border: '1px solid #ede9e8',
    borderRadius:'5px',
    flex: '0 0 auto',
    margin : '1%',
    transition: '0.3s',
    "&:hover": {
      boxShadow: '0 1.5rem 2rem rgba(0,0,0,.2)',
      cursor: 'pointer'
    }
  },
  figure:{
    width: '200px',
    height: '200px',
    margin: '0',
    padding: '0',
    background: '#fff',
    overflow: 'hidden',
  },
  cardImg:{
    borderBottom: '1px solid grey',
    borderRadius:'5px 5px',
    width : '200px',
    height : '200px',
    objectFit : 'cover',
    webkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)',
    webkitTransition: '.3s ease-in-out',
    transition: '.3s ease-in-out',
    "&:hover": {    
      webkitFilter: 'grayscale(0)',
      filter: 'grayscale(0)',
    },
  },
});

export default function SubCategories() {
  const [allSubCategories , setAllSubCategories] = useState([]);
  const [loading , setLoading] = useState(true);

  const classes = useStyles();

      // ---- Load subCategories data to display -------
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(loadSubCategories()); 
        setLoading(false);
    },[dispatch]);
    
    // ---- Load subCategories from reduxStore and set into component state -------
    const subCategoriesStateObj = useSelector( rootState => rootState.subCategoriesState );
    useEffect(()=>{
      if(subCategoriesStateObj.success){
        setAllSubCategories(subCategoriesStateObj.subCategories);
      }  
    },[subCategoriesStateObj]);

    // ---- Delete subCategories handler -------
    const deleteSubCategoryHandler = id => {
      Confirm('Are you sure to delete this SubCategory?' ,  result => {
        if(result){
          setLoading(true);
          dispatch(deleteSubCategory(id));
        }
      })
    }

    // ---- Check either subCategory deleted or Not -------
    useEffect(()=>{
      if(subCategoriesStateObj.action){
        dispatch(loadSubCategories()); 
        setLoading(false);
      }
  },[subCategoriesStateObj , dispatch]);


    if(loading){
      return <Loader />
    }

    return (
      <>  
        <div style={{display:'flex' ,justifyContent:'center' ,marginBottom:'2%'}}>
              <h1 style={{marginTop:'1.2%'}}>Sub Categories</h1>
              <CustomIconButton 
                link = {`subCategories/create`}
                title = "Create SubCategory"
                icon = "AddCircleOutlinedIcon"
              />
        </div>
          { !(allSubCategories.length)  ? (<h2> No Record Found! </h2>) 
                          : <div className={classes.container} >
                              {allSubCategories.map( subCategory => {
                                return (
                                    <div key={subCategory.id} className={classes.root}>
                                      <figure className={classes.figure}>
                                        <img src={subCategory.image} alt="Avatar" className={classes.cardImg} />
                                      </figure>
                                      <div style={{padding:'5% 5% 0% 5%'}}>
                                        <h3>{subCategory.title}</h3>

                                        <hr style={{width:'100%' , margin:'auto'}} />
                                        <div style={{display:'flex' , justifyContent:'space-between'}} >
                                          <div>
                                          <CustomIconButton 
                                            link = {`subCategories/view/${subCategory.id}`}
                                            title = "View SubCategory"
                                            icon = "VisibilityIcon"
                                          />
                                          <CustomIconButton 
                                            link = {`subCategories/edit/${subCategory.id}`}
                                            title = "Edit SubCategory"
                                            icon = "EditIcon"
                                          />
                                          </div>
                                          <div >
                                            <DeleteButton 
                                              title = "Delete SubCategory"
                                              handler = {() => deleteSubCategoryHandler(subCategory.id)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                )
                              })}
                            </div>
                      
                          
          }
      </>
    );
}
