import axios from '../../services/axios';
import { LOAD_SUBCATEGORIES , SUBCATEGORY_SET_ACTION , GET_SUBCATEGORY_BY_ID , SUBCATEGORY_UPDATED_SET} from './actions_types';
import setAuthToken from './../../utils/setAuthToken';
import toastHandler from '../../components/ToastNotification/toastHandler';


export const loadSubCategories = (size,page) => async dispatch => {
    let apiURL = '/api/subCategories/get_subCategories';
    if(size){
        apiURL = `/api/subCategories/get_subCategories/?size=${size}&page=${page}`;
    }
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get(apiURL);
            dispatch({
                type : LOAD_SUBCATEGORIES ,
                payload : {subCategories:res.data.subCategories , totalRecords:res.data.totalRecords}  
            });
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const createSubCategory = (formData) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.post('/api/subCategories/create_subCategory', formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            if(res.data){
                dispatch({
                    type : SUBCATEGORY_SET_ACTION ,
                });
                toastHandler('SubCategory created successfully!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}



export const getSubCategoryById = (id) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/subCategories/'+id);
            if(res.data){
                dispatch({
                    type : GET_SUBCATEGORY_BY_ID ,
                    payload : { subCategory:res.data.subCategory }  
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}



export const updateSubCategory = (formData ,  subCategoryId) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.put('/api/subCategories/update_subCategory/?subCategoryId='+subCategoryId, formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            if(res.data){
                dispatch({
                    type : SUBCATEGORY_UPDATED_SET ,
                });
                toastHandler('SubCategory Updated successfully!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const deleteSubCategory = subCategoryId => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.delete('/api/subCategories/delete_subCategory/?subCategoryId='+subCategoryId);
            if(res){
                toastHandler('SubCategory deleted successfully!' , 'success');
                dispatch({
                    type : SUBCATEGORY_SET_ACTION
                })
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
                dispatch({
                    type : SUBCATEGORY_SET_ACTION
                })
            }
            console.log(error);
        }   
    }
}