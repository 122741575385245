import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CATEGORY_RESET_ACTION } from '../../redux/actions/actions_types';
import { loadCategories , deleteCategory } from '../../redux/actions/categories';
import { CustomIconButton , DeleteButton } from '../actionButtons/actionButtons';

import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';

const useStyles = makeStyles({
  container: {
    display:"flex",
    justifyContent:'center',
    flexWrap:"wrap",
    width: '80%',
    margin : 'auto'
  },
  root: {
    padding : '1%',
    backgroundColor: 'white',
    border: '1px solid #ede9e8',
    borderRadius:'5px',
    flex: '0 0 auto', 
    margin : '1%',
    transition: '0.3s',
    "&:hover": {
      boxShadow: '0 1.5rem 2rem rgba(0,0,0,.2)',
      cursor: 'pointer'
    }
  },
  figure:{
    width: '200px',
    height: '200px',
    margin: '0',
    padding: '0',
    background: '#fff',
    overflow: 'hidden',
  },
  cardImg:{
    borderBottom: '1px solid grey',
    borderRadius:'5px 5px',
    width : '200px',
    height : '200px',
    objectFit : 'cover',
    webkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)',
    webkitTransition: '.3s ease-in-out',
    transition: '.3s ease-in-out',
    "&:hover": {    
      webkitFilter: 'grayscale(0)',
      filter: 'grayscale(0)',
    },
  },
});

export default function Categories() {
  const [allCategories , setAllCategories] = useState([]);
  const [loading , setLoading] = useState(true);

  const classes = useStyles();

      // ---- Load categories data to display -------
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(loadCategories()); 
        setLoading(false);
    },[dispatch]);
    
    // ---- Load categories from reduxStore and set into component state -------
    const categoriesStateObj = useSelector( rootState => rootState.categoriesState );
    useEffect(()=>{
      if(categoriesStateObj.success){
        setAllCategories(categoriesStateObj.categories);
      }  
    },[categoriesStateObj]);

    // ---- Delete category handler ----- 
    const deleteCategoryHandler = id => {
      Confirm('Are you sure to delete this Category?' ,  result => {
        if(result){
          setLoading(true);
          dispatch(deleteCategory(id)); 
        }
      })
    }

    // ---- Check either category delete Or Not ------
    useEffect(()=>{
      if(categoriesStateObj.action){
        dispatch({
          type : CATEGORY_RESET_ACTION
        })
        dispatch(loadCategories()); 
        setLoading(false);
      }  
    },[categoriesStateObj , dispatch]);

    if(loading){
      return <Loader />
    }

    return (
      <>  
        <div style={{display:'flex' ,justifyContent:'center' ,marginBottom:'2%'}}>
              <h1 style={{marginTop:'1.2%'}}>Categories</h1>
              <CustomIconButton 
                link = {`categories/create`}
                title = "Create Category"
                icon = "AddCircleOutlinedIcon"
              />
        </div>
          { !(allCategories.length)  ? (<h2> No Record Found! </h2>) 
                          : <div className={classes.container} >
                              {allCategories.map( category => {
                                return (
                                    <div key={category.id} className={classes.root}>
                                      <figure className={classes.figure}>
                                        <img src={category.image} alt="Avatar" className={classes.cardImg} />
                                      </figure>
                                      <div style={{padding:'5% 5% 0% 5%'}}>
                                        <h3>{category.name}</h3>

                                        <hr style={{width:'100%' , margin:'auto'}} />
                                        <div style={{display:'flex' , justifyContent:'space-between'}} >
                                          <div>
                                          <CustomIconButton 
                                            link = {`categories/view/${category.id}`}
                                            title = "View Category"
                                            icon = "VisibilityIcon"
                                          />
                                          <CustomIconButton 
                                            link = {`categories/edit/${category.id}`}
                                            title = "Edit Category"
                                            icon = "EditIcon"
                                          />
                                          </div>
                                          <div >
                                            <DeleteButton 
                                              title = "Delete Category"
                                              handler = {() => deleteCategoryHandler(category.id)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                )
                              })}
                            </div>
                      
                          
          }
      </>
    );
}
