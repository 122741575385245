export const LOAD_USER = 'LOAD_USER'; 
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_OTP_SENT = 'SET_OTP_SENT';
export const RESET_OTP_SENT = 'RESET_OTP_SENT';
export const SET_OTP_VERIFIED = 'RESET_OTP_VERIFIED';
export const RESET_OTP_VERIFIED = 'RESET_OTP_VERIFIED';
export const SET_PASSWORD_CHANGED = 'SET_PASSWORD_CHANGED';
export const RESET_PASSWORD_CHANGED = 'RESET_PASSWORD_CHANGED';




export const LOAD_USERS = 'LOAD_USERS';
export const USER_SET_ACTION = 'USER_SET_ACTION';
export const USER_RESET_ACTION = 'USER_RESET_ACTION';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const USER_UPDATED_SET = 'USER_UPDATED_SET';
export const USER_UPDATED_RESET = 'USER_UPDATED_RESET';



export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const CATEGORY_SET_ACTION = 'CATEGORY_SET_ACTION';
export const CATEGORY_RESET_ACTION = 'CATEGORY_RESET_ACTION';
export const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID';
export const CATEGORY_UPDATED_SET = 'CATEGORY_UPDATED_SET';
export const CATEGORY_UPDATED_RESET = 'CATEGORY_UPDATED_RESET';



export const LOAD_SUBCATEGORIES = 'LOAD_SUBCATEGORIES';
export const SUBCATEGORY_SET_ACTION = 'SUBCATEGORY_SET_ACTION';
export const SUBCATEGORY_RESET_ACTION = 'SUBCATEGORY_RESET_ACTION';
export const GET_SUBCATEGORY_BY_ID = 'GET_SUBCATEGORY_BY_ID';
export const SUBCATEGORY_UPDATED_SET = 'SUBCATEGORY_UPDATED_SET';
export const SUBCATEGORY_UPDATED_RESET = 'SUBCATEGORY_UPDATED_RESET';



export const LOAD_CLOTHES = 'LOAD_CLOTHES';
export const CLOTH_SET_ACTION = 'CLOTH_SET_ACTION';
export const CLOTH_RESET_ACTION = 'CLOTH_RESET_ACTION';
export const GET_CLOTH_BY_ID = 'GET_CLOTH_BY_ID';
export const CLOTH_UPDATED_SET = 'CLOTH_UPDATED_SET';
export const CLOTH_UPDATED_RESET = 'CLOTH_UPDATED_RESET';



export const LOAD_ORDERS = 'LOAD_ORDERS';
export const ORDER_SET_ACTION = 'ORDER_SET_ACTION';
export const ORDER_RESET_ACTION = 'ORDER_RESET_ACTION';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const ORDER_UPDATED_SET = 'ORDER_UPDATED_SET';
export const ORDER_UPDATED_RESET = 'ORDER_UPDATED_RESET';





export const LOAD_SUBCATEGORIES_AND_PROPERTIES = 'LOAD_SUBCATEGORIES_AND_PROPERTIES';
export const LOAD_DASHBOARD_CONTENTS = 'LOAD_DASHBOARD_CONTENTS';
export const ADMIN_STATE_RESET_ACTION = 'ADMIN_STATE_RESET_ACTION';

