import React,{ useState , useEffect }  from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { TextInputWithLable , FileInput, SelectInput, TextArea, FormSubmitBtn } from '../formComponents/formComponents';
import { loadCategoriesAndProperties } from '../../redux/actions/adminCommon';
import { createCloth } from '../../redux/actions/clothes';
import { CLOTH_RESET_ACTION } from '../../redux/actions/actions_types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import getFileMimeType from '../../helpers/getFileMimeType';
import toastHandler from '../ToastNotification/toastHandler'
import Loader from '../loader/Loader';

import classes from './CommonStyle.module.css';

const CreateCloth = props => {

    const[subCategories , setSubCategories] = useState([]);
    const[properties , setProperties] = useState([]);
    const[formInvalid , setFormInvalid] = useState(true);
    const[files , setFiles] = useState(null);
    const[loading , setLoading] = useState(true);
    
    // ---- Reset local states-------
    const resetStates = () => {
        setFormInvalid(true);
        setFiles(null);
    }

    // ---- Handle file check its mime type -------
    const fileHandler = event => {
        if(event.target.files.length){  
            if(event.target.files.length > 0 && event.target.files.length < 3){
                toastHandler('Each cloth must have 3 Images' , 'error');
            }else{
                const filesArray = [ event.target.files[0],event.target.files[1],event.target.files[2] ];
                let fileTypeValid = true;
                filesArray.forEach( file => {
                    getFileMimeType(file, function(mime) {
                        if(mime !== 'image/jpeg' && mime !== 'image/png'){
                            fileTypeValid = false
                            toastHandler('File type is Invalid Only jpeg , png supported' , 'error');
                            resetStates();
                        }
                    });
                });
                if(fileTypeValid){
                    setFiles(filesArray);
                    setFormInvalid(false)
                }else{
                    setFiles(null);
                    setFormInvalid(true)
                }
            }
        }else{
            resetStates();
        }
    }


    
    // ---- Formik schema validation for createCloth-------
    const createClothSchema = Yup.object().shape({
        title : Yup.string().required('Title is Required!').min(3 ,'Title must be of 3 or more characters') 
                                                            .max(20 ,'Title must be of 20 or less characters'),
        description : Yup.string().required('Description is Required!')
        .test('len', 'Must be Greater then 5 characters', val => val ? val.length >= 5 : '')
        .test('len', 'Must be Less then 100 characters', val =>  val ? val.length <= 100 : ''),
        subCategoryId : Yup.object().shape({value: Yup.number().required('Subcategory is required')}),
        properties : Yup.array().min(1 , 'Cloth must have atleast one Property'),
        price : Yup.number().required('Price is Required').min(5)
    })
    
    // ---- Form initial values -------
    const initialValues = { 
        title : '' ,
        description : '', 
        price : 0,
        subCategoryId : '',
        properties :[]
    }
    
    // ---- Form configurations-------
    const formConfiguration = {
        title : { type:'text', name:'title', lable : 'Title'}, 
        description : { type:'text', name:'description', lable : 'Description'},
        subCategoryId : {name:'subCategoryId'},
        properties : {name:'properties',isMulti:true }  ,
        images : { name:"images",accept:"image/png, image/jpeg" ,lable:"Images" , multiple:true},
        price : { type:'number', name:'price', lable : 'Price'}
    }
    
    // ---- Form submit handler-------
    const dispatch = useDispatch();
    const createClothHandler = values => {
        setLoading(true);
        let formData = new FormData();
        values.properties.forEach( property => {
            formData.append('properties[]', property.value);
        });
        formData.append('subCategoryId', values.subCategoryId.value);
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('price', values.price);
        files.forEach( file => {
            formData.append('images', file);
        });
        dispatch(createCloth(formData));
    }

    // ---- Load CategoriesAndProperties for Select items
    useEffect(()=>{
        dispatch(loadCategoriesAndProperties()); 
    },[dispatch]);
    
    const adminCommonStateObj = useSelector( rootState => rootState.adminCommonState );
    useEffect(()=>{
        if(adminCommonStateObj.success){
            if(adminCommonStateObj.subCategories.length){
                setSubCategories(adminCommonStateObj.subCategories);
            }
            if(adminCommonStateObj.properties.length){
                setProperties(adminCommonStateObj.properties);
            }
            setLoading(false);
        }  
    },[adminCommonStateObj]);

    // ---- Goback when cloth created 
    const history = useHistory();
    const clothesStateObj = useSelector( rootState => rootState.clothesState );
    useEffect(()=>{
      if(clothesStateObj.action){
        dispatch({
            type : CLOTH_RESET_ACTION ,
        });
        setLoading(false);
        history.goBack();
      }  
    },[clothesStateObj,history, dispatch]);


    if(loading){
        return <Loader />
    }
    
    return(
        <div className={classes.ParentDiv} >
            <h1 className="large text-primary">Create Cloth</h1>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={createClothSchema}
                    onSubmit={values => createClothHandler(values)}
                >
                    {(formik) => {
                        
                        const { isValid, dirty , setFieldValue} = formik;
                        return (
                            
                            <Form className={classes.Form} >
                                <TextInputWithLable 
                                    {...formConfiguration['title']}
                                />

                                <SelectInput
                                    setFieldValue={setFieldValue}
                                    lable='Sub Category'
                                    {...formConfiguration['subCategoryId']}
                                    children = {subCategories.length     ? subCategories.map(subCategory => {
                                                                                                return {value:subCategory.id , label:subCategory.title}
                                                                                            })
                                                                            : null }
                                >
                                    
                                </SelectInput>
                                
                                <SelectInput
                                    setFieldValue={setFieldValue}
                                    lable='Properties'
                                    {...formConfiguration['properties']}
                                    children = {properties.length   ? properties.map( property => {
                                                                                        return {value:property.id , label:property.name}
                                                                                    })
                                                                    : null }
                                >
                                    
                                </SelectInput>

                                <br/>                                                                   
                                <TextArea 
                                    {...formConfiguration['description']}
                                />
                                <br/>    
                                <TextInputWithLable 
                                    {...formConfiguration['price']}
                                />

                                <FileInput 
                                   {...formConfiguration['images']}
                                   onChange={fileHandler}
                                />
                                
                                <FormSubmitBtn 
                                    btnTitle="Create" 
                                    dirty={dirty}
                                    isValid={isValid}
                                    formInvalid={formInvalid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
        
    );

}

export default CreateCloth;