import {    LOAD_SUBCATEGORIES ,
            SUBCATEGORY_SET_ACTION ,
            SUBCATEGORY_RESET_ACTION ,
            GET_SUBCATEGORY_BY_ID ,
            SUBCATEGORY_UPDATED_SET ,
            SUBCATEGORY_UPDATED_RESET } from '../actions/actions_types';


const initialState = {
    subCategories : null,
    totalRecords : null,
    success : false,
    action : false,
    subCategory : null,
    updated : false
}


const subCategories = ( state = initialState , action ) => {
    const { type , payload } = action ; 
    switch(type){

        case LOAD_SUBCATEGORIES:
            return {
                ...state ,
                success : true , 
                subCategories : payload.subCategories ,
                totalRecords : payload.totalRecords 
            };

        case SUBCATEGORY_SET_ACTION:
            return {
                ...state ,
                action : true
            };

        case SUBCATEGORY_RESET_ACTION:
            return {
                ...state ,
                action : false
            };

        case GET_SUBCATEGORY_BY_ID:
            return {
                ...state ,
                action : true , 
                subCategory : payload.subCategory ,
            };

        case SUBCATEGORY_UPDATED_SET:
            return {
                ...state ,
                updated : true , 
            };

        case SUBCATEGORY_UPDATED_RESET:
            return {
                ...state ,
                updated : false , 
            };

        default:
            return state;

    }
}

export default subCategories;