import axios from '../../services/axios';
import {    
            LOAD_CATEGORIES ,
            CATEGORY_SET_ACTION ,
            GET_CATEGORY_BY_ID ,
            CATEGORY_UPDATED_SET , 
        }   from './actions_types';
import setAuthToken from './../../utils/setAuthToken';
import toastHandler from '../../components/ToastNotification/toastHandler';


export const loadCategories = (size,page) => async dispatch => {
    let apiURL = '/api/categories/get_categories';
    if(size){
        apiURL = `/api/categories/get_categories/?size=${size}&page=${page}`;
    }
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get(apiURL);
            dispatch({
                type : LOAD_CATEGORIES ,
                payload : {categories:res.data.categories}  
            });
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const createCategory = (formData) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.post('/api/categories/create_category', formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            if(res.data){
                dispatch({
                    type : CATEGORY_SET_ACTION ,
                });
                toastHandler('Category created successfully!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
                dispatch({
                    type : CATEGORY_SET_ACTION ,
                });
            }
            console.log(error);
        }   
    }
}


export const getCategoryById = (id) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/categories/'+id);
            if(res.data){
                dispatch({
                    type : GET_CATEGORY_BY_ID ,
                    payload : { category:res.data.category }  
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}

export const updateCategory = (formData ,  categoryId) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.put('/api/categories/update_category/?categoryId='+categoryId, formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            if(res.data){
                dispatch({
                    type : CATEGORY_UPDATED_SET ,
                });
                toastHandler('Category Updated successfully!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}



export const deleteCategory = categoryId => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.delete('/api/categories/delete_category/?categoryId='+categoryId);
            if(res.data){
                toastHandler('Category deleted successfully!' , 'success');
                dispatch({
                    type : CATEGORY_SET_ACTION
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
                dispatch({
                    type : CATEGORY_SET_ACTION
                });
            }
            console.log(error);
        }   
    }
}
