import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { useParams ,useHistory } from 'react-router-dom';
import { getClothById , deleteCloth  } from '../../redux/actions/clothes';
import { CLOTH_RESET_ACTION  } from '../../redux/actions/actions_types';
import Carousel from 'react-material-ui-carousel';
import classes from './CommonStyle.module.css';
import { BackButton , CustomIconButton , DeleteButton } from '../actionButtons/actionButtons'
import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';


export default function ViewCloth( props ) {
    
    const[cloth , setCloth] = useState();
    const[loading , setLoading] = useState(true);
    const[isDeleted , setIsDeleted] = useState(false);

    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }

    // Load Cloth data by id
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getClothById(id)); 
    },[dispatch , id]);
    
    // ---- Set clothData from redux to local state
    const clothesStateObj = useSelector( rootState => rootState.clothesState );
    useEffect(()=>{
      if(clothesStateObj.action){
        dispatch({
          type : CLOTH_RESET_ACTION ,
        });
        setCloth(clothesStateObj.cloth);
        setLoading(false);
      }  
    },[clothesStateObj , dispatch]);


     // ---- Delete cloth handler -------
     const deleteClothHandler = id => {
      Confirm('Are you sure to delete this Cloth?' , result => {
        if(result){
          setLoading(true);
          setIsDeleted(true);
          dispatch(deleteCloth(id));
        }
      });
    }

    // ---- Check either cloth deleted or Not ----
    useEffect(()=>{
      if(clothesStateObj.action && isDeleted){
        dispatch({
          type : CLOTH_RESET_ACTION ,
        });
        setLoading(false);
        history.goBack();
      }
    },[clothesStateObj,dispatch , history , isDeleted]);

  
    if(loading){
      return <Loader />
    }
  

  return (
    <div>
      <h1 className="large text-primary">View Cloth</h1>
      <div className={classes.ViewParentDiv}>
        <div className={classes.carousalContainer}>
          <Carousel
            autoPlay={true}
            animation={"fade"}
            indicators={true}
            timeout={500}
            navButtonsAlwaysVisible={true}
          >
            {
                cloth.images.map((image, index) => {
                  return <div key={index} className={classes.imgContainer}>
                            <img
                              src={image.url}
                              alt={index}
                            />
                          </div>
                })
            }
        </Carousel>
        Images
        </div>
          <div className={classes.clothViewContainer}>
            <h1>Detail</h1>
            <div><h3>Title:</h3><p>{cloth.title}</p></div>
            <hr></hr>
            <div><h3>Description:</h3><p style={{marginLeft:'15%' ,  height:'60px'}}>{cloth.description}</p></div>
            <hr></hr>
            <div><h3>Properties:</h3><p style={{marginLeft:'15%'}}>{cloth.properties.map(prop => prop+' ')}</p></div>
            <hr></hr>
            <div><h3>Price:</h3><p>{cloth.price}</p></div>
            <hr></hr>
            <br></br>
            <br></br>
            <BackButton />
            <CustomIconButton 
                link = {`/dashboard/clothes/edit/${cloth.id}`}
                title = "Edit Cloth"
                icon = "EditIcon"
            />
            <DeleteButton 
              title = "Delete Cloth"
              handler = {() => deleteClothHandler(cloth.id)}
            />
          </div>
      </div>
       
    </div>
  );
}