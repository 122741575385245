import React,{ useState , useEffect }  from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory , useParams } from "react-router-dom";
import { TextInputWithLable , FormSubmitBtn } from '../formComponents/formComponents';
import { getUserById , updateUser } from '../../redux/actions/users';
import { USER_RESET_ACTION , USER_UPDATED_RESET } from '../../redux/actions/actions_types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import classes from './CommonStyle.module.css';
import getFileMimeType from '../../helpers/getFileMimeType';
import toastHandler from '../ToastNotification/toastHandler';
import Loader from '../loader/Loader';


const UpdateUser = props => {
    
    const[user , setUser] = useState({ firstName : ''});
    const[loading , setLoading] = useState(true);
    const[imageUrl , setImageUrl] = useState('');
    const[file , setFile] = useState(null);

    

    // ---- Formik schema validation for Update Profile-------
    const updateProfileSchema = Yup.object().shape({
        firstName : Yup.string().required('FirstName is required').min(3 ,'FirstName must be of 3 or more characters') 
                                                            .max(20 ,'FirstName must be of 20 or less characters'),
        lastName : Yup.string().required('LastName is required').min(3 ,'LastName must be of 3 or more characters') 
                                                            .max(20 ,'LastName must be of 20 or less characters'),
        email : Yup.string().email('Enter valid email').required('Email is Required!') , 
        password : Yup.string().min(8 ,'Password must be of 8 or more characters') 
                                                                .max(15 ,'Password must be of 15 or less characters')
                                                                .matches(
                                                                    '(?=.*[a-z]).{8,}$' , 
                                                                    "Password must contain , One Digit and one Letter"
                                                                ),
        confirmPassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ).required('Confirm Password is Required')
          })
    })
    
    // ---- Form Configuration-------
    const formConfiguration = {
        firstName : { type:'text', name:'firstName', lable : 'First Name'}, 
        lastName : { type:'text', name:'lastName', lable : 'Last Name'},
        email : { type:'email', name:'email', lable : 'Email'},
        password : {type:'password', name:'password',lable : 'Password'} , 
        confirmPassword : {type:'password', name:'confirmPassword',lable : 'Confirm Password'} , 
    }
    
    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }

    // ---- Handle file check its mime type -------
    const fileHandler = event => {
        if(event.target.files[0]){  
            getFileMimeType(event.target.files[0], function(mime) {
                if(mime === 'image/jpeg' || mime === 'image/png'){
                    setImageUrl(URL.createObjectURL(event.target.files[0]))
                    setFile(event.target.files[0]);
                }else{
                    setFile(null);
                    toastHandler('File type is Invalid Only jpeg , png supported' , 'error');
                }
            });
        }else{
            setFile(null);
        }
    }
    
    // ---- Load User by id to display in form
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getUserById(id));
    },[dispatch , id]);
    
    // ---- Set User obj from redux into local states
    const usersStateObj = useSelector( rootState => rootState.usersState );
    useEffect(()=>{
      if(usersStateObj.action){
        dispatch({
          type : USER_RESET_ACTION
        });
        const UserObjToDisplay = usersStateObj.user;
        usersStateObj.password = '';
        setUser(UserObjToDisplay);
        let imageSrc = UserObjToDisplay.image ? UserObjToDisplay.image  : "https://i.pravatar.cc/200?u=" + UserObjToDisplay.email;
        setImageUrl(imageSrc);
        setLoading(false);
      }  
    },[usersStateObj, dispatch ]);

    // ---- Form submit handler
    const updateUserHandler = values => {
        let formData = new FormData();
        formData.append('firstName' ,values.firstName);
        formData.append('lastName' ,values.lastName); 
        formData.append('email' ,values.email); 
        if(values.password && values.password.length){
            formData.append('password' ,values.password); 
        }
        if(file){
            formData.append('image',file)
        }
        dispatch(updateUser(formData , user.id));
    }
    
    // ---- goBack when User Updated 
    useEffect(()=>{
      if(usersStateObj.updated){
        dispatch({
            type : USER_UPDATED_RESET ,
        });
        history.goBack();
      }  
    },[usersStateObj,history, dispatch]);
    
    if(loading){
        return <Loader />
    }
    
    return(
        <div className={classes.ParentDiv} >
            <h1 className="large text-primary">Update Profile</h1>
            <div>

            <Formik
                    enableReinitialize
                    initialValues={user}
                    validationSchema={updateProfileSchema}
                    onSubmit={values => updateUserHandler(values)}
                >
                    {(formik) => {
                        
                        const { isValid, dirty } = formik;
                        return (
                            <Form className={classes.Form} >
                                <div className={classes.imageContainer} >
                                    <img 
                                        src={imageUrl}
                                        alt='Profile'
                                    />
                                    <div className={classes.middle}>
                                        <label htmlFor="profilePic"> 
                                            <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp;&nbsp;Edit
                                        </label> 
                                        <input
                                            style={{marginLeft:'10%',display:'none'}}
                                            type="file"
                                            id="profilePic"
                                            name="image"
                                            accept="image/png, image/jpeg"
                                            onChange={fileHandler}
                                        />
                                    </div>
                                </div>
                              

                                <TextInputWithLable 
                                    {...formConfiguration['firstName']}
                                />

                                <TextInputWithLable 
                                    {...formConfiguration['lastName']}
                                />

                                <TextInputWithLable 
                                    {...formConfiguration['email']}
                                />

                                <TextInputWithLable 
                                    {...formConfiguration['password']}
                                />

                                <TextInputWithLable 
                                    {...formConfiguration['confirmPassword']}
                                />


                                
                                <div style={{display:'flex' , justifyContent:'center'}}>
                                    <FormSubmitBtn 
                                        btnTitle="Update" 
                                        dirty={dirty}
                                        isValid={isValid}
                                        // formInvalid={formInvalid}
                                    />
                                </div>
                            </Form>
                        );
                       
                        
                    }}
                </Formik>
            </div>
        </div>  
    );
}

export default UpdateUser;