import React ,{ useEffect, useState } from 'react';
import { useDispatch  , useSelector  } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TextInput , FormSubmitBtn } from './../../formComponents/formComponents';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { verifyOTP } from '../../../redux/actions/auth';
import { RESET_OTP_VERIFIED } from '../../../redux/actions/actions_types';
import loginPageImgSrc  from  '../../../assets/images/tailor1.jpg';
import Loader from '../../loader/Loader';

import classes from './Common.module.css';

const VerifyOTP = props => {

    const [loading , setLoading] = useState(false);

    const dispatch = useDispatch();

    // ---- formik schema for verifyOTP
    const verifyEmailSchema = Yup.object().shape({
        otp : Yup.string().required('OTP is required').min(6 ,'OTP must be of 6 or more characters') 
        .max(6 ,'OTP must be of 6 or less characters'),
       
    })

    // ---- form initial values
    const initialValues = { 
        otp : '' , 
    }

    // ---- form configurations
    const formConfiguration = {
        otp : {type:'text', name:'otp',lable : 'OTP'} , 
    }

    // ---- form submit handler
    const authStateObj = useSelector( rootState => rootState.authState );
    const verifyOTPHandler = formData => {
        setLoading(true);
        dispatch(verifyOTP({otp: formData.otp , email:authStateObj.emailForResetPassword }));
    }

    // ---- Navigate to changePassword  Screen when OTP done
    const history = useHistory();
    useEffect(() => {
        if(authStateObj.otpVerified){
            dispatch({
                type : RESET_OTP_VERIFIED
            });
            setLoading(false);
            history.push('/changePassword');
        }
        
    },[dispatch , authStateObj , history]);
    
    return(
        <div className={classes.parentDiv}>

            <div className={classes.sideDiv}>
                <img src={loginPageImgSrc} alt="loginPage" />
            </div>

            <div className={classes.FormDiv} >
                <h1 className="large text-primary"> Verify OTP</h1>
                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={verifyEmailSchema}
                        onSubmit={values => verifyOTPHandler(values)}
                    >
                        {(formik) => {
                            
                            const { isValid, dirty} = formik;
                            return (
                                
                                <Form className={classes.Form} >

                                    {
                                        loading ? <Loader /> 
                                                : <TextInput 
                                                    {...formConfiguration['otp']}
                                                />

                                    }

                                    
                                    
                                    <br/>
                                    <FormSubmitBtn 
                                        btnTitle="Verify OTP" 
                                        dirty={dirty}
                                        isValid={isValid}
                                    />

                                </Form>
                                
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
        
    );

}

export default VerifyOTP;