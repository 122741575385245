import  { 
            LOAD_USERS ,
            USER_SET_ACTION,
            USER_RESET_ACTION,
            GET_USER_BY_ID,
            USER_UPDATED_SET,
            USER_UPDATED_RESET
        }   
            from '../actions/actions_types';


const initialState = {
    users : null,
    success : false,
    user : null,
    action : false,
    updated : false,
    totalRecords : null,
}


const users = ( state = initialState , action ) => {
    const { type , payload } = action ; 
    switch(type){

        case LOAD_USERS:
            return {
                ...state ,
                success : true , 
                users : payload.users ,
                totalRecords : payload.totalRecords 
            };
    
        case USER_SET_ACTION:
            return {
                ...state ,
                action : true
            };

        case USER_RESET_ACTION:
            return {
                ...state ,
                action : false
            };
        
        case GET_USER_BY_ID:
            return {
                ...state ,
                action : true , 
                user : payload.user,
            };

        case USER_UPDATED_SET:
            return {
                ...state ,
                updated : true , 
            };

        case USER_UPDATED_RESET:
            return {
                ...state ,
                updated : false , 
            };

        default:
            return state;

    }
}

export default users;