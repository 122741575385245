import React  from 'react';
import { useDispatch  } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextInput , FormSubmitBtn } from './../../formComponents/formComponents';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { loginUser } from '../../../redux/actions/auth';
import loginPageImgSrc  from  '../../../assets/images/tailor1.jpg';

import classes from './Login.module.css';

const Login = props => {

    const dispatch = useDispatch();

    // ---- formik schema for signin
    const signInSchema = Yup.object().shape({
        email : Yup.string().email('Enter valid email').required('Email is Required!'), 
        password : Yup.string().required('Password is Required'),
    })

    // ---- form initial values
    const initialValues = { 
        email : '' , 
        password : '',
    }

    // ---- form configurations
    const formConfiguration = {
        email : {type:'email', name:'email',lable : 'Email'} , 
        password : {type:'password', name:'password',lable : 'Password'},
    }

    // ---- form submit handler
    const loginHandler = formData => {
        const {email , password} = formData ; 
        dispatch(loginUser({email , password}));
    }

    
    return(
        <div className={classes.parentDiv}>

            <div className={classes.sideDiv}>
                <img src={loginPageImgSrc} alt="loginPage" />
            </div>

            <div className={classes.FormDiv} >
                <h1 className="large text-primary"><i className="fas fa-user"></i> LogIn</h1>
                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        onSubmit={values => loginHandler(values)}
                    >
                        {(formik) => {
                            
                            const { isValid, dirty} = formik;
                            return (
                                
                                <Form className={classes.Form} >
                                    <TextInput 
                                        {...formConfiguration['email']}
                                    />
                                    
                                    <TextInput 
                                        {...formConfiguration['password']}
                                    />

                                    <br/>
                                    <FormSubmitBtn 
                                        btnTitle="Log in" 
                                        dirty={dirty}
                                        isValid={isValid}
                                    />

                                    <Link 
                                        to="/verifyEmail"
                                    >
                                        Forget your Password ?
                                    </Link>
                                </Form>
                                
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
        
    );

}

export default Login;