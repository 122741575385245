import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    menuButton: {
      marginRight: 36,
      color:'black',
      '& :hover' : {
        color:'black'
      }
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      blackSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor:'white',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor:'white',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      backgroundColor:'white',
      color:'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent:'space-between',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      "& a": {
        color:"black",
        textDecoration:"none",
        marginLeft:'10px',
        marginTop:'15px',
        "&:hover": {
          color:'rgb(23, 24, 23)'
        }
      }
    },
    content: {
      width : '100%',
      height : '100vh',
      backgroundColor: '#f7f7f7',
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: '7%',
      // marginBottom: '20%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '13%',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '23%',
        width : '70%',
      },
    },
    LeftIcon: {
      color:'black',
      "&:hover": {
        color:'rgb(23, 24, 23)'
      }
    },
    Link :{
      border: '1px solid transparent',
      borderRadius:'5px',
      padding : '7px 10px 1px 10px',
      marginLeft :'5px',
      color:'black',
      textDecoration:'none',
      outline:'none',
      boxSizing:'border-box',
      fontWeight:'bold',
      "&:hover, &:focus": {
        color:'rgb(23, 24, 23)'
      }
    } , 
    BreadCrumLink : {
      marginTop:'1%',
      color:'black',
      textDecoration:'none',
      "&:hover": {       
        color:'rgb(23, 24, 23)'
      }
    },
    Header : {
      backgroundColor:'#ffff',
    },
    HeaderDiv: {
      display:'flex' , 
      flexDirection:'row',
      justifyContent:'space-between',
      width:'100%',
      paddingTop:'1%'
    }
  
  }));