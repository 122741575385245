import React  from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useStyles } from './DashboardStyle';
import CustomMenuItem from './menuItem/CustomMenuItem';
import SidebarItems from './sideBarItems/SidebarItems';
import DashboardRouter from '../../router/DashboardRouter';


export default function DashboardContainer() {

  // --------Drawer Open/Close Handler Start
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
  // --------Drawer Open/Close Handler End
    
  
    
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <CssBaseline />
            {/* -------------- Header Start------------- */}
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar className={clsx(classes.Header)}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div className={clsx(classes.HeaderDiv)} >
                    
                    <Link className={classes.BreadCrumLink} color="inherit" to='/dashboard' >
                      <h2 style={{letterSpacing:'1px'}}>
                        ShalwarKameez
                      </h2>
                    </Link>

                    <CustomMenuItem />

                  </div>
                </Toolbar>
              </AppBar>
            {/* -------------- Header End------------- */}



            {/* -------------- Side Drawer Start------------- */}
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                <div className={classes.toolbar}>
                  <Link to='/dashboard' >
                    <h1>
                      Dashboard
                    </h1>
                  </Link>                 
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon className={classes.LeftIcon} fontSize="large" />}
                  </IconButton>
                </div>
                <Divider />
                

                {/* SidebarItems items Start */}
                  <SidebarItems isDrawerOpen={open}  />
                {/* SidebarItems items End */}


              </Drawer>
            {/* -------------- Side Drawer End------------- */}

        <main className={classes.content}>
            <DashboardRouter />
        </main>

    </div>
  );
}
