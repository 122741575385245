import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { Link , useParams ,useHistory } from 'react-router-dom';
import { getOrderById  } from '../../redux/actions/orders';
import { ORDER_RESET_ACTION  } from '../../redux/actions/actions_types';
import classes from './CommonStyle.module.css';
import { BackButton } from '../actionButtons/actionButtons'
import Loader from '../loader/Loader';


export default function ViewOrder( props ) {
    
    const[order , setOrder] = useState();
    const[loading , setLoading] = useState(true);

    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }

    // Load Order data by id
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getOrderById(id)); 
    },[dispatch , id]);
    
    // ---- Set OrderDate from redux to local state
    const ordersStateObj = useSelector( rootState => rootState.ordersState );
    useEffect(()=>{
      if(ordersStateObj.action){
        dispatch({
          type : ORDER_RESET_ACTION ,
        });
        setOrder(ordersStateObj.order);
        setLoading(false);
      }  
    },[ordersStateObj , dispatch]);

  
    if(loading){
      return <Loader />
    }
  

  return (
    <div>
      <h1 >View Order</h1>
      <br></br>
      <br></br>
        <h2>Order Clothes Detail </h2>
        <div className={classes.OrderProductsContainer}>
            {order.orderProducts.map( (product , index) => {
              return (
                <div key={index} className={classes.productParentDiv}>
                  { index === 0 ? <><div className={classes.Product}><h4 style={{width:'40%'}}>Cloth Name</h4><h4 style={{width:'30%'}}>Price</h4><h4 style={{width:'30%'}}>Quantity</h4></div> <hr></hr> </>: null}
                  <div className={classes.Product}>
                    <h4 style={{width:'40%'}}>
                      <Link className={classes.link} to={`/dashboard/clothes/view/${product.productId}`}>
                        {product.productName}
                      </Link>
                    </h4>
                    <span style={{width:'30%'}}>{product.price}</span>
                    <span style={{width:'30%',paddingLeft:'2%'}}>{product.quantity}</span>
                  </div>
                  { index === order.orderProducts.length-1 ? null : <hr></hr>}
                </div>
              );
            })}
        </div>
       
        
        <div className={classes.orderDetailViewContainer}>
            <h2>Order Detail</h2>
            <div><h3>User Name:</h3>
              <p>
                <Link className={classes.link}  to={`/dashboard/users/view/${order.user.id}`}>
                  {order.user.firstName + ' ' +order.user.lastName}
                </Link>
              </p>
            </div>
            <hr></hr>
            <div><h3>Complete:</h3><p >{order.processed ? "Yes" : "No"}</p></div>
            <hr></hr>
            <div><h3>Price:</h3><p>{order.total}</p></div>
            <hr></hr>
            <br/>
            <br/>
            <BackButton />
          </div>
    </div>
  );
}