import React,{ useState , useEffect }  from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { TextInputWithLable , FileInput, FormSubmitBtn } from '../formComponents/formComponents';
import { createCategory } from '../../redux/actions/categories';
import { CATEGORY_RESET_ACTION } from '../../redux/actions/actions_types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import getFileMimeType from '../../helpers/getFileMimeType';
import toastHandler from '../ToastNotification/toastHandler'
import Loader from '../loader/Loader';
import classes from './CommonStyle.module.css';

const CreateCategory = props => {

    const[formInvalid , setFormInvalid] = useState(true);
    const[file , setFile] = useState(null);
    const[loading , setLoading] = useState(false);
    
    // ---- Reset local components states
    const resetStates = () => {
        setFormInvalid(true);
        setFile(null);
    }

    // ---- Handle file check its mime type -------
    const fileHandler = event => {
        if(event.target.files[0]){  
            getFileMimeType(event.target.files[0], function(mime) {
                if(mime === 'image/jpeg' || mime === 'image/png'){
                    setFile(event.target.files[0]);
                    setFormInvalid(false)
                }else{
                    resetStates();
                    toastHandler('File type is Invalid Only jpeg , png supported' , 'error');
                }
            });
        }else{
            resetStates();
        }
    }

    // ---- Formik schema validation for createCategory-------
    const createCategorySchema = Yup.object().shape({
        name : Yup.string().required('Name is Required!').min(3 , 'Name must be of 3 or more characters')
                                                         .max(15 , 'Name must be of 15 or less characters') , 
    })
    
    // ---- Form initial values -------
    const initialValues = { 
        name : '' , 
    }
    
    // ---- Form configurations-------
    const formConfiguration = {
        name : {type:'text', name:'name',lable : 'Name'} , 
        image : { name:"image",accept:"image/png, image/jpeg" ,lable:"Image"}
    }
    
    // ---- Form submit handler-------
    const dispatch = useDispatch();
    const createCategoryHandler = values => {
        setLoading(true);
        let formData = new FormData();
        formData.append('name' ,values.name)
        formData.append('image',file)
        dispatch(createCategory(formData));
    }

    // ---- Goback when category create-------
    const history = useHistory();
    const categoriesStateObj = useSelector( rootState => rootState.categoriesState );
    useEffect(()=>{
      if(categoriesStateObj.action){
        dispatch({
            type : CATEGORY_RESET_ACTION ,
        });
        setLoading(false);
        history.goBack();
      }  
    },[categoriesStateObj,history, dispatch]);
    
    
    if(loading){
        return <Loader />
    }

    return(
        <div className={classes.ParentDiv} >
            <h1 className="large text-primary">Create Category</h1>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={createCategorySchema}
                    onSubmit={values => createCategoryHandler(values)}
                >
                    {(formik) => {
                        
                        const { isValid, dirty} = formik;
                        return (
                            
                            <Form className={classes.Form} >
                                <TextInputWithLable 
                                    {...formConfiguration['name']}
                                />
                                
                                <FileInput 
                                   {...formConfiguration['image']}
                                   onChange={fileHandler}
                                />
                                
                                <FormSubmitBtn 
                                    btnTitle="Create" 
                                    dirty={dirty}
                                    isValid={isValid}
                                    formInvalid={formInvalid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
        
    );

}

export default CreateCategory;