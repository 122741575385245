import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    justifyContent : 'flex-end',
    zIndex: theme.zIndex.drawer + 1,
   
  },
  backdropContent : {
    display : 'flex',
    flexDirection : 'column',
    height : '100vh',
    width : '25%',
    padding : '10% 3%',
    backgroundColor : '#f7f7f7',
    [theme.breakpoints.down('md')]: { 
      width : '30%',
    },
    [theme.breakpoints.down('sm')]: {
      width : '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width : '80%',
    },
  }
}));



export default function Filter(props) {
  const classes = useStyles();

  return props.filterOpen ? <Backdrop className={classes.backdrop} open={props.filterOpen} onClick={props.closeHandler}>
                              <div className={classes.backdropContent} onClick={e => e.stopPropagation()}>
                                {props.children}
                              </div>
                            </Backdrop>
                          : null
   
}