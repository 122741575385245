import { combineReducers } from 'redux';
import authReducer from './auth';
import usersReducer from './users';
import categoriesReducer from './categories';
import subCategoriesReducer from './subCategories';
import clothesReducer from './clothes';
import adminCommonReducer from './adminCommon';
import orderReducer from './orders';

const rootReducer = combineReducers({
    authState : authReducer,
    usersState : usersReducer,
    subCategoriesState : subCategoriesReducer,
    clothesState : clothesReducer,
    categoriesState : categoriesReducer,
    adminCommonState : adminCommonReducer,
    ordersState : orderReducer
});

export default rootReducer;