import { LOAD_CLOTHES , CLOTH_SET_ACTION , CLOTH_RESET_ACTION , GET_CLOTH_BY_ID , CLOTH_UPDATED_SET , CLOTH_UPDATED_RESET } from '../actions/actions_types';


const initialState = {
    clothes : null,
    totalRecords : null,
    success : false,
    action : false,
    cloth : null,
    updated : false
}


const clothes = ( state = initialState , action ) => {
    const { type , payload } = action ; 
    switch(type){

        case LOAD_CLOTHES:
            return {
                ...state ,
                success : true , 
                clothes : payload.clothes ,
                totalRecords : payload.totalRecords 
            };
            
        case CLOTH_SET_ACTION:
            return {
                ...state ,
                action : true
            };

        case CLOTH_RESET_ACTION:
            return {
                ...state ,
                action : false
            };
        
        case GET_CLOTH_BY_ID:
            return {
                ...state ,
                action : true , 
                cloth : payload.cloth,
            };

        case CLOTH_UPDATED_SET:
            return {
                ...state ,
                updated : true , 
            };

        case CLOTH_UPDATED_RESET:
            return {
                ...state ,
                updated : false , 
            };

        default:
            return state;

    }
}

export default clothes;