import React,{ useState , useEffect }  from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory , useParams } from "react-router-dom";
import { TextInputWithLable , FileInput, FormSubmitBtn } from '../formComponents/formComponents';
import { updateCategory , getCategoryById } from '../../redux/actions/categories';
import { CATEGORY_RESET_ACTION , CATEGORY_UPDATED_RESET } from '../../redux/actions/actions_types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import getFileMimeType from '../../helpers/getFileMimeType';
import toastHandler from '../ToastNotification/toastHandler';
import classes from './CommonStyle.module.css';
import Loader from '../loader/Loader';

const EditCategory = props => {

    const[formInvalid , setFormInvalid] = useState(true);
    const[imageUrl , setImageUrl] = useState('');
    const[file , setFile] = useState(null);
    const[category , setCategory] = useState({ name : '' });
    const[loading , setLoading] = useState(true);
    
    // ---- Reset local components states
    const resetStates = () => {
        setFormInvalid(true);
        setFile(null);
    }

    // ---- Handle file check its mime type -------
    const fileHandler = event => {
        if(event.target.files[0]){  
            getFileMimeType(event.target.files[0], function(mime) {
                if(mime === 'image/jpeg' || mime === 'image/png'){
                    setImageUrl(URL.createObjectURL(event.target.files[0]))
                    setFile(event.target.files[0]);
                    setFormInvalid(false)
                }else{
                    resetStates();
                    toastHandler('File type is Invalid Only jpeg , png supported' , 'error');
                }
            });
        }else{
            resetStates();
        }
    }

    // ---- Formik schema validation for updateCategory-------
    const updateCategorySchema = Yup.object().shape({
      name : Yup.string().required('Name is Required!').min(3 , 'Name must be of 3 or more characters')
                                                        .max(15 , 'Name must be of 15 or less characters') ,
    })
    
    // ---- Form configurations-------
    const formConfiguration = {
      name : {type:'text', name:'name',lable : 'Name'} , 
      image : { name:"image",accept:"image/png, image/jpeg" ,lable:"Image"}
    }
    
    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }

    // ---- Form submit handler-------
    const editCategoryHandler = values => {
        let formData = new FormData();
        formData.append('name' ,values.name)
        if(file){
          formData.append('image',file)
        }
        dispatch(updateCategory(formData , category.id));
    }

    // Load Category data by id
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(getCategoryById(id)); 
    },[dispatch , id]);
  
    // Set Category data from redux to local state
    const categoriesStateObj = useSelector( rootState => rootState.categoriesState );
    useEffect(()=>{
      if(categoriesStateObj.action){
        dispatch({
          type : CATEGORY_RESET_ACTION,
        });
        setCategory(categoriesStateObj.category);
        setImageUrl(categoriesStateObj.category.image);
        setFormInvalid(false);
        setLoading(false);
      }  
    },[categoriesStateObj,dispatch ,category , formInvalid]);

    // ---- Goback when category update-------
    useEffect(()=>{
      if(categoriesStateObj.updated){
        dispatch({
          type : CATEGORY_UPDATED_RESET,
        });
        history.goBack();
      }  
    },[categoriesStateObj,dispatch , history]);
    
    
    if(loading){
      return <Loader />
    }
    return(
        <div className={classes.ParentDiv} >
            <h1 >Update Category</h1>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={category}
                    validationSchema={updateCategorySchema}
                    onSubmit={values => editCategoryHandler(values)}
                >
                    {(formik) => {
                        
                        const { isValid, dirty} = formik;
                        return (
                            <Form className={classes.Form} >
                              <div>
                                <img 
                                  width="100px"
                                  src={imageUrl}
                                  alt='category'
                                />
                              </div>

                              <TextInputWithLable 
                                  {...formConfiguration['name']}
                              />
                              
                              
                              <FileInput 
                                  {...formConfiguration['image']}
                                  onChange={fileHandler}
                              />

                              <FormSubmitBtn 
                                  btnTitle="Update" 
                                  dirty={dirty}
                                  isValid={isValid}
                                  // formInvalid={formInvalid}
                              />
                            </Form>
                        );
                       
                        
                    }}
                </Formik>
            </div>
        </div>
        
    );

}

export default EditCategory;