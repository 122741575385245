
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Confirm = ( message,callback) => {
    return confirmAlert({
        title: 'Confirm to submit',
        message: message,
        buttons: [
        {
            label: 'Yes',
            onClick: () => {callback(1)}
        },
        {
            label: 'No',
            onClick: () => {callback(0)}
        }
        ]
    });
}

export default Confirm;