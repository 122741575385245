import React from 'react';
import { Link , useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';


export const CustomIconButton = ( props ) => {
    const { title , icon , link } = props;

    let IconButtonJSX = '';
    switch(icon){
        case 'VisibilityIcon':
            IconButtonJSX =  <VisibilityIcon fontSize="small" color="secondary" titleAccess={title} />
            break;

        case 'EditIcon':
            IconButtonJSX =  <EditIcon fontSize="small" color="primary" titleAccess={title} />
            break;
        
        case 'ListAltIcon':
            IconButtonJSX =  <ListAltIcon fontSize="small" color="primary" titleAccess={title} />
            break;
        
        case 'AddCircleOutlinedIcon':
            IconButtonJSX =  <AddCircleOutlinedIcon fontSize="large" color="primary" titleAccess={title} />
            break;

        default:
            break;

    }

    return (
        <Link to={link} >
            <IconButton  title={title} component="span" >
                {IconButtonJSX}
            </IconButton>
        </Link>
         
    );
}


export const DeleteButton = ( props ) => {
    const { title , handler } = props;
    return (
        <IconButton  title={title} component="span" onClick={handler} >
            <DeleteForeverOutlinedIcon fontSize="small" style={{color:'red'}} titleAccess={title} />
        </IconButton>
    );
}

export const MarkDoneButton = ( props ) => {
    const { title , handler , disabled } = props;

    return (
        <IconButton disabled={disabled} title={title} component="span" onClick={handler} >
            <DoneTwoToneIcon fontSize="small" style={{color : disabled ? "green" : 'blue'}} titleAccess={title} />
        </IconButton>
    );
}



export const BackButton =  event  => {
    const history = useHistory();
 
    return (
            <IconButton
                title={'Back'}
                onClick={()=>history.goBack()}
            >
                <ArrowBackIcon titleAccess="Back" />
            </IconButton> 
    );
}




export const FilterButton =  props  => {
    const useStyles = makeStyles((theme) => ({
        filter: {
            marginTop : "2%",
            marginLeft : "5%",
            background: "none",
            color: "inherit",
            border: "none",
            padding: "0",
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
        },
    }));

    const classes = useStyles();
    return (
        <button className={classes.filter} onClick={props.handler}>
            <i className='fas fa-filter' ></i>
        </button>
    );
}


