import {    LOAD_USER , LOGIN_USER ,
            LOGOUT_USER , SET_OTP_SENT ,
            RESET_OTP_SENT, SET_OTP_VERIFIED ,
            RESET_OTP_VERIFIED,
            SET_PASSWORD_CHANGED,
            RESET_PASSWORD_CHANGED } from '../actions/actions_types';


const initialState = {
    token : localStorage.getItem('access_token'),
    isAuthenticated : false , 
    userObj : null,
    otpSent : false,
    otpVerified : false,
    passwordChanged : false,
    emailForResetPassword : null
}


const auth = ( state = initialState , action ) => {
    const { type , payload } = action ; 
    
    switch( type ){

        case LOAD_USER:
            return {
                ...state , 
                isAuthenticated : true , 
                userObj : payload 
            };

        case LOGIN_USER:
            return {
                ...state , 
                isAuthenticated : true , 
                userObj : payload
            };

        case SET_OTP_SENT:
            return {
                ...state , 
                otpSent : true,
                emailForResetPassword : payload.email
            };

        case RESET_OTP_SENT:
            return {
                ...state , 
                otpSent : false
            };   

        case SET_OTP_VERIFIED:
            return {
                ...state , 
                otpVerified : true,
               
            };

        case RESET_OTP_VERIFIED:
            return {
                ...state , 
                otpVerified : false
            };   

        case SET_PASSWORD_CHANGED:
            return {
                ...state , 
                passwordChanged : true,
                emailForResetPassword : null
            };

        case RESET_PASSWORD_CHANGED:
            return {
                ...state , 
                passwordChanged : false
            };  
        
        case LOGOUT_USER:
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            return {
                token : null,
                isAuthenticated : false , 
                userObj : null
            }

        default:
            return state;

    }
}

export default auth;