import React ,{ useEffect, useState } from 'react';
import { useDispatch  , useSelector  } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TextInput , FormSubmitBtn } from './../../formComponents/formComponents';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { changePassword } from '../../../redux/actions/auth';
import { RESET_PASSWORD_CHANGED } from '../../../redux/actions/actions_types';
import loginPageImgSrc  from  '../../../assets/images/tailor1.jpg';

import classes from './Common.module.css';
import Loader from '../../loader/Loader';

const ChangePassword = props => {

    const[loading , setLoading] = useState(false);

    const dispatch = useDispatch();

    // ---- formik schema for ChangePassword
    const changePasswordSchema = Yup.object().shape({
        password : Yup.string().required('Password is Required').min(8 ,'Password must be of 8 or more characters') 
                                                                .max(15 ,'Password must be of 15 or less characters')
                                                                .matches(
                                                                    '(?=.*[a-z]).{8,}$' , 
                                                                    "Password must contain , One Digit and one Letter"
                                                                ),
        confirmPassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            )
          })
    })

    // ---- form initial values
    const initialValues = { 
        password : '' , 
        confirmPassword : ''
    }

    // ---- form configurations
    const formConfiguration = {
        password : {type:'password', name:'password',lable : 'Password'} , 
        confirmPassword : {type:'password', name:'confirmPassword',lable : 'Confirm Password'} , 
    }

    // ---- form submit handler
    const authStateObj = useSelector( rootState => rootState.authState );
    const verifyEmailHandler = formData => {
        setLoading(true);
        dispatch(changePassword({password: formData.password , email:authStateObj.emailForResetPassword }));
    }

    // ---- Navigate to Login Screen when ChangePassword done
    const history = useHistory();
    useEffect(() => {
        if(authStateObj.passwordChanged){
            dispatch({
                type : RESET_PASSWORD_CHANGED
            })
            setLoading(false);
            history.push('/login');
        }
        
    },[dispatch , authStateObj , history]);
    
    return(
        <div className={classes.parentDiv}>

            <div className={classes.sideDiv}>
                <img src={loginPageImgSrc} alt="loginPage" />
            </div>

            <div className={classes.FormDiv} >
                <h1 className="large text-primary"> Change Password</h1>
                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={changePasswordSchema}
                        onSubmit={values => verifyEmailHandler(values)}
                    >
                        {(formik) => {
                            
                            const { isValid, dirty} = formik;
                            return (
                                
                                <Form className={classes.Form} >

                                    {loading    ? <Loader /> 
                                                :   <>
                                                        <TextInput 
                                                            {...formConfiguration['password']}
                                                        />
                                                        <br></br>
                                                        <TextInput 
                                                            {...formConfiguration['confirmPassword']}
                                                        />
                                                    </>
                                                
                                    }
                                    
                                    
                                  

                                    <br/>
                                    <FormSubmitBtn 
                                        btnTitle="Change Password" 
                                        dirty={dirty}
                                        isValid={isValid}
                                    />

                                   
                                </Form>
                                
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
        
    );

}

export default ChangePassword;