import React,{ useState , useEffect }  from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory , useParams } from "react-router-dom";
import { TextInputWithLable , FileInput, SelectInput, FormSubmitBtn } from '../formComponents/formComponents';
import { updateSubCategory , getSubCategoryById } from '../../redux/actions/subCategories';
import { loadCategories } from '../../redux/actions/categories';
import { SUBCATEGORY_RESET_ACTION , SUBCATEGORY_UPDATED_RESET } from '../../redux/actions/actions_types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import getFileMimeType from '../../helpers/getFileMimeType';
import toastHandler from '../ToastNotification/toastHandler'
import classes from './CommonStyle.module.css';
import Loader from '../loader/Loader';

const UpdateSubCategory = props => {

    const[allCategories , setAllCategories] = useState([]);
    const[subCategory , setSubCategory] = useState({ title : ''});
    const[imageUrl , setImageUrl] = useState('')
    const[file , setFile] = useState(null);
    const[loading , setLoading] = useState(true);
    
    // ---- Handle file check its mime type -------
    const fileHandler = event => {
        if(event.target.files[0]){  
            getFileMimeType(event.target.files[0], function(mime) {
                if(mime === 'image/jpeg' || mime === 'image/png'){
                    setImageUrl(URL.createObjectURL(event.target.files[0]))
                    setFile(event.target.files[0]);
                }else{
                    setFile(null);
                    toastHandler('File type is Invalid Only jpeg , png supported' , 'error');
                }
            });
        }else{
            setFile(null);
        }
    }

    // ---- Formik schema validation for updateSubCategory-------
    const updateSubCategorySchema = Yup.object().shape({
        title : Yup.string().required('Title is Required!').min(3 ,'Title must be of 3 or more characters') 
                                                            .max(20 ,'Title must be of 20 or less characters'),
        categoryId : Yup.object().shape({value: Yup.number().required('Category is required')}),
    })
    
    // ---- Form Configuration-------
    const formConfiguration = {
        title : { type:'text', name:'title', lable : 'Title'}, 
        image : { name:"image",accept:"image/png, image/jpeg" ,lable:"Image"},
        categoryId : {name:'categoryId'},
    }
    
    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }
    
    // ---- Load categories for Select item and Sucategory by id to display in form
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(loadCategories());  
        dispatch(getSubCategoryById(id));
    },[dispatch , id]);
    
    // ---- Set categories for Select item and Sucategory obj from redux into local states
    const subCategoriesStateObj = useSelector( rootState => rootState.subCategoriesState );
    const categoriesStateObj = useSelector( rootState => rootState.categoriesState );
    useEffect(()=>{
      if(subCategoriesStateObj.action && categoriesStateObj.success ){
        dispatch({
          type : SUBCATEGORY_RESET_ACTION
        })
        if(categoriesStateObj.categories){
            setAllCategories(categoriesStateObj.categories);
        }
        setImageUrl(subCategoriesStateObj.subCategory.image)
        const subCategoryFullObj = subCategoriesStateObj.subCategory;
        const oldCategoryObj = categoriesStateObj.categories.find(category => category.id === subCategoryFullObj.categoryId );
        const selectedCategory = {value:oldCategoryObj.id , label:oldCategoryObj.name}
        const Obj = {id:subCategoryFullObj.id, title : subCategoryFullObj.title , categoryId : selectedCategory}
        setSubCategory(Obj);
        setLoading(false);
      }  
    },[subCategoriesStateObj,categoriesStateObj, dispatch]);

    // ---- Form submit handler
    const updateSubCategoryHandler = values => {
        let formData = new FormData();
        formData.append('title' ,values.title);
        formData.append('categoryId' ,values.categoryId.value); 
        if(file){
          formData.append('image',file);
        }
        dispatch(updateSubCategory(formData , subCategory.id));
    }
    
    // ---- goBack when SubCategory Updated 
    const subCategoryStateObj = useSelector( rootState => rootState.subCategoriesState );
    useEffect(()=>{
      if(subCategoryStateObj.updated){
        dispatch({
            type : SUBCATEGORY_UPDATED_RESET ,
        });
        history.goBack();
      }  
    },[subCategoryStateObj,history, dispatch]);
    
    if(loading){
        return <Loader />
    }
    
    return(
        <div className={classes.ParentDiv} >
            <h1 className="large text-primary">Update SubCategory</h1>
            <div>

            <Formik
                    enableReinitialize
                    initialValues={subCategory}
                    validationSchema={updateSubCategorySchema}
                    onSubmit={values => updateSubCategoryHandler(values)}
                >
                    {(formik) => {
                        
                        const { isValid, dirty , setFieldValue } = formik;
                        return (
                            <Form className={classes.Form} >
                              <div>
                                <img 
                                  width="100px"
                                  src={imageUrl}
                                  alt='category'
                                />
                              </div>

                              <TextInputWithLable 
                                    {...formConfiguration['title']}
                                />


                                <SelectInput
                                    setFieldValue={setFieldValue}
                                    lable='Category'
                                    {...formConfiguration['categoryId']}
                                    children = {allCategories.length    ? allCategories.map(category => {
                                                                                            return {value:category.id , label:category.name}
                                                                                        })
                                                                        : null }
                                >
                                    
                                </SelectInput>
                                
                                <FileInput 
                                   {...formConfiguration['image']}
                                   onChange={fileHandler}
                                />
                                
                                <FormSubmitBtn 
                                    btnTitle="Update" 
                                    dirty={dirty}
                                    isValid={isValid}
                                    // formInvalid={formInvalid}
                                />
                            </Form>
                        );
                       
                        
                    }}
                </Formik>
            </div>
        </div>
        
    );

}

export default UpdateSubCategory;