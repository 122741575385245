import React,{ useState , useEffect }  from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useHistory , useParams } from "react-router-dom";
import { TextInputWithLable , SelectInput,TextArea, FormSubmitBtn } from '../formComponents/formComponents';
import { loadCategoriesAndProperties } from '../../redux/actions/adminCommon';
import { updateCloth  , getClothById} from '../../redux/actions/clothes';
import { CLOTH_RESET_ACTION , CLOTH_UPDATED_RESET } from '../../redux/actions/actions_types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import getFileMimeType from '../../helpers/getFileMimeType';
import toastHandler from '../ToastNotification/toastHandler'
import Loader from '../loader/Loader';

import classes from './CommonStyle.module.css';

const EditCloth = props => {

    const[subCategories , setSubCategories] = useState([]);
    const[properties , setProperties] = useState([]);
    const[formInvalid , setFormInvalid] = useState(true);
    const[files , setFiles] = useState([]);
    const[clothImages , setClothImages] = useState([]);
    const[loading , setLoading] = useState(true);
    
    // ---- Reset local states-------
    const resetStates = () => {
        setFormInvalid(true);
        setFiles(null);
    }

    // ---- Handle file check its mime type -------
    const fileHandler = event => {
        if(event.target.files[0]){  
            getFileMimeType(event.target.files[0], function(mime) {
                if(mime === 'image/jpeg' || mime === 'image/png'){
                    const targetImageId = parseInt( event.target.id);
                    const fileContent = event.target.files[0];
                    const newImageObj = { id: targetImageId , url: URL.createObjectURL(event.target.files[0])};
                    const targetImageIndex = clothImages.findIndex( image => image.id ===  targetImageId );
                    const oldImages = [...clothImages];
                    oldImages[targetImageIndex] = newImageObj
                    const oldFiles = [...files];
                    const oldfileIndex = files.findIndex( obj => obj.id === targetImageId);
                    const newObjToUpdate = {id:targetImageId , fileContent : fileContent };
                    if(oldfileIndex === -1){
                        oldFiles.push(newObjToUpdate);
                    }else{
                        oldFiles[oldfileIndex] = newObjToUpdate;
                    }
                    setFiles(oldFiles);
                    setClothImages(oldImages);
                    setFormInvalid(false)
                }else{
                    resetStates();
                    toastHandler('File type is Invalid Only jpeg , png supported' , 'error');
                }
            });
        }else{
            resetStates();
        }
    }

    // ---- Formik schema validation for updateCloth-------
    const updateClothSchema = Yup.object().shape({
        title : Yup.string().required('Title is Required!').min(3 ,'Title must be of 3 or more characters') 
                                                            .max(20 ,'Title must be of 20 or less characters'),
        description : Yup.string().required('Description is Required!')
        .test('len', 'Must be Greater then 5 characters', val => val ? val.length >= 5 : '')
        .test('len', 'Must be Less then 100 characters', val =>  val ? val.length <= 100 : ''),
        subCategoryId : Yup.object().shape({value: Yup.number().required('Subcategory is required')}),
        properties : Yup.array().min(1 , 'Cloth must have atleast one Property'),
        price : Yup.number().required('Price is Required').min(5)
    })
    
    // ---- Form configurations-------
    const formConfiguration = {
        title : { type:'text', name:'title', lable : 'Title'}, 
        description : { type:'text', name:'description', lable : 'Description'},
        subCategoryId : {name:'subCategoryId'},
        properties : {name:'properties',isMulti:true }  ,
        images : { name:"images",accept:"image/png, image/jpeg" ,lable:"Images" , multiple:true},
        price : { type:'number', name:'price', lable : 'Price'}
    }
    
    // ---- Form submit handler-------
    const dispatch = useDispatch();
    const updateClothHandler = values => {
        let formData = new FormData();
        values.properties.forEach( property => {
            formData.append('properties[]', property.value);
        });
        formData.append('subCategoryId', values.subCategoryId.value);
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('price', values.price);
        if(files){
            files.forEach( file => {
                formData.append('images', file.fileContent);
                formData.append('updatedImagesIdes[]', file.id);
            });
        }
        dispatch(updateCloth(formData , cloth.id));
    }

    // ---- form initial values -------
    const[cloth , setCloth] = useState({
        title : '' ,
        description : '', 
        price : 0,
        subCategoryId : '',
        properties :[]
    });

    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }

    // ----Load clothData and loadCategoriesAndProperties for Select items
    useEffect(()=>{
        dispatch(loadCategoriesAndProperties()); 
        dispatch(getClothById(id)); 
    },[dispatch , id ]);
    
    // ---- Set clothData and loadCategoriesAndProperties to local states 
    const adminCommonStateObj = useSelector( rootState => rootState.adminCommonState );
    const clothesStateObj = useSelector( rootState => rootState.clothesState );
    useEffect(()=>{
        if(adminCommonStateObj.success && clothesStateObj.action){
            dispatch({
                type : CLOTH_RESET_ACTION ,
            });
            const clothOldObj = clothesStateObj.cloth;
            const selectedProperties = [];
            adminCommonStateObj.properties.forEach( property => {
                if((clothOldObj.properties).includes(property.name)){
                selectedProperties.push({value:property.id , label:property.name });
                }
            })
            let selectedSubCategory = {};
            adminCommonStateObj.subCategories.forEach( subCategory => {
                if(subCategory.id === clothOldObj.subCategoryId){
                selectedSubCategory =  { value:subCategory.id , label:subCategory.title }
                }
            });
            setSubCategories(adminCommonStateObj.subCategories);
            setProperties(adminCommonStateObj.properties);
            setCloth({...clothOldObj , properties:selectedProperties ,subCategoryId:selectedSubCategory })
            setClothImages(clothOldObj.images);
            setFormInvalid(false);
            setLoading(false);
        }  
    },[adminCommonStateObj , clothesStateObj , dispatch]);
    
    // ---- Goback when cloth update-------
    useEffect(()=>{
      if(clothesStateObj.updated){
        dispatch({
            type : CLOTH_UPDATED_RESET ,
        });
        history.goBack();
      }  
    },[clothesStateObj,history, dispatch]);

    if(loading){
        return <Loader />
    }

    return(
        <div >
            <h1 className="large text-primary">Update Cloth</h1>
            <div className={classes.editClothContainer}>
                <div className={classes.images} >
                    { clothImages.length  ? clothImages.map((image , index) => {
                                                return  <div key={index} className={classes.imageContainer} >
                                                            <img  src={image.url} alt={'image'+index+1} />
                                                            <div className={classes.middle}>
                                                                <label htmlFor={image.id}> 
                                                                    <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp;&nbsp;Edit Image
                                                                </label> 
                                                                <input
                                                                    style={{marginLeft:'10%',display:'none'}}
                                                                    type="file"
                                                                    id={image.id}
                                                                    name={image.id}
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={fileHandler}
                                                                />
                                                            </div>
                                                        </div>
                                            })
                                            : null
                    }
                    
                </div>
            
                <div className={classes.form}>
                    <Formik
                        enableReinitialize
                        initialValues={cloth}
                        validationSchema={updateClothSchema}
                        onSubmit={values => updateClothHandler(values)}
                    >
                        {(formik) => {
                            
                            const { isValid, dirty , setFieldValue} = formik;
                            return (
                                
                                <Form >
                                    <TextInputWithLable 
                                        {...formConfiguration['title']}
                                    />

                                    <SelectInput
                                        setFieldValue={setFieldValue}
                                        lable='Sub Category'
                                        {...formConfiguration['subCategoryId']}
                                        children = {subCategories.length     ? subCategories.map(subCategory => {
                                                                                                    return {value:subCategory.id , label:subCategory.title}
                                                                                                })
                                                                                : null }
                                    >
                                        
                                    </SelectInput>
                                    
                                    <SelectInput
                                        setFieldValue={setFieldValue}
                                        lable='Properties'
                                        {...formConfiguration['properties']}
                                        children = {properties.length   ? properties.map( property => {
                                                                                            return {value:property.id , label:property.name}
                                                                                        })
                                                                        : null }
                                    >
                                        
                                    </SelectInput>

                                    <br/>                                                                   
                                    <TextArea 
                                        {...formConfiguration['description']}
                                    />
                                    <br/>  
                                    <TextInputWithLable 
                                        {...formConfiguration['price']}
                                    />

                                    <br/>
                                    <br/>
                                    <br/>
                                    <FormSubmitBtn 
                                        btnTitle="Update" 
                                        dirty={dirty}
                                        isValid={isValid}
                                        formInvalid={formInvalid}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
        
    );

}

export default EditCloth;