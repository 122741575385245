import React ,  { useEffect, useState }  from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { Bar , Line } from 'react-chartjs-2';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { loadDashboardContents } from '../../redux/actions/adminCommon';
import { ADMIN_STATE_RESET_ACTION } from '../../redux/actions/actions_types';
import Loader from '../loader/Loader';
import classes from './DashboardContent.module.css';

const RevenueData = {
  labels: ['2015', '2016', '2017', '2018', '2019', '2020'],
  datasets: [
    {
      label: 'Revenue',
      data: [10, 29, 31, 25, 34, 40],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1.5,
    },
  ],
};


const salesData = {
  labels: ['2015', '2016', '2017', '2018', '2019', '2020'],
  datasets: [
    {
      label: 'sales',
      data: [10, 29, 31, 25, 34, 40],
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const DashboardContent = () => {
  
  const [dashboardContent, setDashboardContent] = useState({});
  const [loading , setLoading] = useState(true);

  const dispatch = useDispatch();

 // ---- Load dasboard  data to display -------
  useEffect(()=>{
    dispatch(loadDashboardContents());
    setLoading(false);
  },[dispatch])

  // ---- Load dashboard content from reduxStore and set into component state -------
  const dashboardStateObj = useSelector( rootState => rootState.adminCommonState);
  useEffect(()=>{
    if(dashboardStateObj.success){
      dispatch({
        type : ADMIN_STATE_RESET_ACTION
      })
      setDashboardContent(dashboardStateObj.dashboardContent);
      setLoading(false);
    }  
  },[dashboardStateObj , dispatch]);

  if(loading){
    return <Loader />
  }
  
  return (
    <>
      <div className={classes.Card_Container}>
        
        <div className={classes.Card}>
        <PeopleIcon titleAccess="View Attendance" fontSize="large"  />
          <div>
            <h2>{dashboardContent.totalUsers}</h2>
            <p>Users</p>
          </div>
        </div>
  
        <div className={classes.Card}>
          <AttachMoneyIcon titleAccess="View Attendance" fontSize="large"  />
          <div>
            <h2 >{dashboardContent.Earning} </h2>
            <p>Earning</p>
          </div>
        </div>
  
        <div className={classes.Card}>
          <ListAltIcon titleAccess="View Attendance" fontSize="large"  />
          <div>
            <h2 >{dashboardContent.totalOrders}  </h2>
            <p>Total Orders</p>
          </div>
        </div>
  
        <div className={classes.Card}>
        <FormatListBulletedIcon titleAccess="View Attendance" fontSize="large"  />
          <div>
            <h2>{dashboardContent.pendingOrders}  </h2>
            <p>Pending Orders</p>
          </div>
        </div>
  
      </div>
  
  
      <div className={classes.Chart_Container}>
        <div className={classes.Chart} >
          <div>
            <h1 >Total Revenue</h1>
          </div>
          <Bar data={RevenueData} options={options} />
        </div>
  
        <div className={classes.Chart} >
          <div >
            <h1 >Sales</h1>
          </div>
          <Line data={salesData} options={options} />
        </div>
  
      </div>
    </>
  );
}


export default DashboardContent;