import  { 
    LOAD_ORDERS ,
    ORDER_SET_ACTION,
    ORDER_RESET_ACTION,
    GET_ORDER_BY_ID,
    ORDER_UPDATED_SET,
    ORDER_UPDATED_RESET
}   
    from '../actions/actions_types';


const initialState = {
    orders : null,
    success : false,
    order : null,
    action : false,
    updated : false,
    totalRecords : null,
}


const orders = ( state = initialState , action ) => {
    const { type , payload } = action ; 

    switch(type){
        case LOAD_ORDERS:
            return {
                ...state ,
                success : true , 
                orders : payload.orders ,
                totalRecords : payload.totalRecords 
            };

        case ORDER_SET_ACTION:
            return {
                ...state ,
                action : true
            };

        case ORDER_RESET_ACTION:
            return {
                ...state ,
                action : false
            };

        case GET_ORDER_BY_ID:
            return {
                ...state ,
                action : true , 
                order : payload.order,
            };

        case ORDER_UPDATED_SET:
            return {
                ...state ,
                updated : true , 
            };

        case ORDER_UPDATED_RESET:
            return {
                ...state ,
                updated : false , 
            };

        default:
            return state;

    }
}

export default orders;