import React  from 'react';
import { Link , useRouteMatch } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import getItemList from './itemsList';

import classes from './SidebarItems.module.css';


const SidebarItems = props => {

    const {isDrawerOpen} = props;

    let { path } = useRouteMatch();
    let SideDrawerItem = getItemList(path);

    // --------Sidebar Items open/Close handler End
    const [selectedIndex, setSelectedIndex] = React.useState("")
    const handleClick = index => {
        if (selectedIndex === index) {
        setSelectedIndex("")
        } else {
        setSelectedIndex(index)
        }
    }

    // --------Sidebar Items open/Close handler End

    return(
        <List>
            {SideDrawerItem.map((item, index) => {
                return (
                <div key={index}>

                    <ListItem button onClick={() => {
                        handleClick(index)
                    }}>
                    <div style={{display:'flex'}}>
                        { item.link ?   <Link to={item.link} className={classes.sideBarLinks} >
                                            <div style={{display:'flex'}}>
                                                <ListItemIcon><i className={item.icon}></i></ListItemIcon>
                                                <ListItemText primary={item.name} />
                                            </div>
                                        </Link> 
                                    : 
                                        <div className={classes.sideBarLinks}>
                                            <div style={{display:'flex'}}>
                                                <ListItemIcon><i className={item.icon}></i></ListItemIcon>
                                                <ListItemText primary={item.name} />
                                            </div>
                                        </div>
                        }
                        
                        {
                            item.submenu.length ?  <div style={{color:"black",marginLeft:'10%'}}>
                                                        {index === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                                                    </div>
                                                : null
                        }
                       
                    </div>
                    </ListItem>
                    { item.submenu.length ? <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                                                <List component="div" >
                                                    { item.submenu.map((sub, subIndex) => {
                                                                            return (
                                                                                <ListItem style={{paddingLeft : isDrawerOpen ? '15%': '30%' }} button key={subIndex} >
                                                                                    <Link to={sub.link} className={classes.sideBarLinks} >
                                                                                        <div style={{display:'flex'}}>
                                                                                            <ListItemIcon><i className={sub.icon}></i></ListItemIcon>
                                                                                            <ListItemText primary={sub.name} />
                                                                                        </div>
                                                                                    </Link>
                                                                                </ListItem>
                                                                            )
                                                                            })
                                                                        
                                                    }
                                                </List>
                                            </Collapse> 
                                        : null
                    }
                    
                </div>
                )
            })}
        </List>
    );
}

export default SidebarItems;