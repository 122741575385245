import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';

import { USER_RESET_ACTION } from '../../redux/actions/actions_types';
import { loadUsers  , deleteUser} from '../../redux/actions/users';
import { CustomIconButton  , DeleteButton } from '../actionButtons/actionButtons';

import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';


const useStyles = makeStyles((theme) => ({
  container: {
    display:"flex",
    // justifyContent:'center',
    flexWrap:"wrap",
    width: '100%',
    margin : 'auto'
  },
  root: {
    textAlign : 'left',
    padding : '0% 1%',
    backgroundColor: 'white',
    border: '1px solid #ede9e8',
    borderRadius:'5px',
    flex: '0 0 auto', 
    margin : '1%',
    transition: '0.3s',
    boxSizing : 'border-box',
    width : '18%',
    "&:hover": {
      boxShadow: '0 1.5rem 2rem rgba(0,0,0,.2)',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width : '30%',
    },
    [theme.breakpoints.down('sm')]: {
      width : '46%',
    },
    [theme.breakpoints.down('xs')]: {
      width : '90%',
    },
  },
  cardContent : {
    display : 'flex'
    
  },
  figure:{
    width: '30%',
    justifyContent:'center',
    marginTop: '7%',
    padding: '0',
    overflow: 'hidden',
 
  },
  cardImg:{
    borderBottom: '1px solid grey',
    borderRadius:'50%',
    width : '50px',
    height : '50px',
    objectFit : 'cover',
    webkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)',
    webkitTransition: '.3s ease-in-out',
    transition: '.3s ease-in-out',
    "&:hover": {    
      webkitFilter: 'grayscale(0)',
      filter: 'grayscale(0)',
    },
  },
  pTag:{
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Pagination : {
    width : '35%',
    marginLeft: "1%",
    [theme.breakpoints.down('md')]: {
      width : '35%',
    },
    [theme.breakpoints.down('sm')]: {
      width : '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width : '90%',
    },
  }
}));

export default function Users() {
  const [allUsers , setAllUser] = useState([]);
  const [loading , setLoading] = useState(true);

  const classes = useStyles();

    // ---- Pagination -------
    const [totalRecords , setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const dispatch = useDispatch();
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        dispatch(loadUsers(rowsPerPage , newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value);
        setRowsPerPage(value);
        dispatch(loadUsers(value , page));
    };


    // ---- Load Users data to display -------
    useEffect(()=>{
        dispatch(loadUsers()); 
    },[dispatch]);
    
    // ---- Load Users from reduxStore and set into component state -------
    const usersStateObj = useSelector( rootState => rootState.usersState );
    useEffect(()=>{
      if(usersStateObj.success){
        setAllUser(usersStateObj.users);
        setTotalRecords(usersStateObj.totalRecords);
        setLoading(false);
      }  
    },[usersStateObj]);

    // ---- Delete User handler ----- 
    const deleteUserHandler = id => {
      Confirm('Are you sure to delete this User?' ,result => {
        if(result){
          setLoading(true);
          dispatch(deleteUser(id)); 
        }
      });
    }

    // ---- Check either User Delete Or Not ------
    useEffect(()=>{
      if(usersStateObj.action){
        dispatch({
          type : USER_RESET_ACTION
        })
        dispatch(loadUsers()); 
        setLoading(false);
      }  
    },[usersStateObj , dispatch]);

    if(loading){
      return <Loader />
    }

    return (
      <>  
        <div style={{display:'flex' ,justifyContent:'center' ,marginBottom:'2%'}}>
            <h1 style={{marginTop:'1.2%'}}>Users</h1>
        </div>
          { !(allUsers.length)  ? (<h2> No Record Found! </h2>) 
                          : <div className={classes.container} >
                              {allUsers.map( user => {
                                const avatarSrc = user.image ? user.image : "https://i.pravatar.cc/200?u=" + user.email; 
                                return (
                                    <div key={user.id}  className={classes.root}>
                                      <div className={classes.cardContent}>

                                        <figure className={classes.figure}>
                                          <img src={avatarSrc} alt="Avatar" className={classes.cardImg} />
                                        </figure>
                                        <div style={{padding:'10% 5% 0% 5%' , width:'70%'}}>
                                          <h3>{user.firstName + ' ' + user.lastName} </h3>
                                          <p className={classes.pTag}>{user.email}</p>
                                        </div>
                                      </div>
                                      <hr style={{width:'100%' , margin:'auto'}} />
                                          <div style={{display:'flex' , justifyContent:'space-between'}} >
                                            <div>
                                            <CustomIconButton 
                                              link = {`users/view/${user.id}`}
                                              title = "View User"
                                              icon = "VisibilityIcon"
                                            />
                                            </div>
                                            <div>
                                            <CustomIconButton 
                                              link = {`users/edit/${user.id}`}
                                              title = "Edit User"
                                              icon = "EditIcon"
                                            />
                                          </div>
                                            <div>
                                            <CustomIconButton 
                                              link = {`orders?userId=${user.id}`}
                                              title = "View User Orders"
                                              icon = "ListAltIcon"
                                            />
                                            </div>
                                            <div >
                                              <DeleteButton 
                                                title = "Delete User"
                                                handler = {() => deleteUserHandler(user.id)}
                                              />
                                            </div>
                                          </div>
                                    </div>
                                )
                              })}
                  
                              
                            </div>
          }
          <div className={classes.Pagination}>
            <TablePagination
              style={{border:'1px solid gray', borderRadius:'3px'  }}
              component="div"
              count={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
      </>
    );
}
