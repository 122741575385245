import React  from 'react';
import { TextInputWithLable ,CheckBox, FormSubmitBtn } from './../formComponents/formComponents';
import { Formik, Form } from "formik";

const OrdersFilters = props => {

    // ---- form initial values
    const initialValues = { 
        from : '',
        to : '' , 
        processed : false
    }

    // ---- form configurations
    const formConfiguration = {
        from : {type:'date', name:'from',lable : 'From'},
        to : {type:'date', name:'to',lable : 'To'} , 
        processed : {type:'checkbox', name:'processed',lable : 'Completed'}
    }

    // ---- form submit handler
    const loginHandler = formData => {
        const modifiedData = {...formData};
        if(modifiedData.to !== ''){
            modifiedData.to = new Date(modifiedData.to).toISOString().slice(0, 19).replace('T', ' ');
        }else{
            delete modifiedData.to;
        }
        if(modifiedData.from !== ''){
            modifiedData.from = new Date(modifiedData.from).toISOString().slice(0, 19).replace('T', ' ');
        }else{
            delete modifiedData.from;
        }
        props.filterApplyHandler(modifiedData);
    }

    
    return(

            <div  >
                <h1 className="large text-primary"> Filters</h1>
                <div>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={values => loginHandler(values)}
                    >
                        {(formik) => {
                            
                            const { isValid, dirty} = formik;
                            return (
                                
                                <Form  >
                                    <TextInputWithLable 
                                        {...formConfiguration['from']}
                                    />
                                    
                                    <TextInputWithLable 
                                        {...formConfiguration['to']}
                                    />

                                    <CheckBox 
                                        {...formConfiguration['processed']}
                                    >
                                        Completed
                                    </CheckBox>
                                    
                                    <br/>
                                    <FormSubmitBtn 
                                        btnTitle="Apply" 
                                        dirty={dirty}
                                        isValid={isValid}
                                    />
                                </Form>
                                
                            );
                        }}
                    </Formik>
                </div>
            </div>
       
        
    );

}

export default OrdersFilters;