import axios from '../../services/axios';
import { LOAD_USERS , USER_SET_ACTION , GET_USER_BY_ID , USER_UPDATED_SET , LOAD_USER } from './actions_types';

import setAuthToken from './../../utils/setAuthToken';
import toastHandler from '../../components/ToastNotification/toastHandler';


export const loadUsers = (size,page) => async dispatch => {
    let apiURL = '/api/users/get_users';
    if(size){
        apiURL = `/api/users/get_users/?size=${size}&page=${page}`;
    }
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get(apiURL);
            dispatch({
                type : LOAD_USERS ,
                payload : {users:res.data.users , totalRecords:res.data.totalRecords}  
            });
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const deleteUser = userId => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.delete('/api/users/delete_user/?userId= '+userId);
            if(res){
                toastHandler('User deleted successfully!' , 'success');
                dispatch({
                    type : USER_SET_ACTION
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
                dispatch({
                    type : USER_SET_ACTION
                });
            }
            console.log(error);
        }   
    }
}


export const getUserById = (id) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/users/'+id);
            if(res.data){
                dispatch({
                    type : GET_USER_BY_ID ,
                    payload : { user:res.data.user }  
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}



export const updateUser = (formData ,  userId) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.put('/api/users/update_user_by_admin/?userId='+userId, formData , {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if(res.data){
                if(userId === 1){ //// if user is admin then reload its profile data
                    dispatch({
                        type : LOAD_USER , 
                        payload : res.data.user
                    }) 
                }
                dispatch({
                    type : USER_UPDATED_SET ,
                });
                toastHandler('Profile Updated successfully!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}