import axios from '../../services/axios';
import { LOAD_ORDERS , GET_ORDER_BY_ID , ORDER_UPDATED_SET} from './actions_types';
import setAuthToken from './../../utils/setAuthToken';
import toastHandler from '../../components/ToastNotification/toastHandler';
import serialize from '../../helpers/serialize';

export const loadOrders = ( pagination = null , filter = null ) => async dispatch => {
    let apiURL = '/api/orders/get_orders_admin?';
    if(pagination && pagination.size){
        apiURL += `size=${pagination.size}&page=${pagination.page}`;
    }
    if(Object.keys(filter).length){
        const queryString = serialize(filter);
        apiURL += '&'+ queryString;
    }
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get(apiURL);
            dispatch({
                type : LOAD_ORDERS ,
                payload : {orders:res.data.orders , totalRecords:res.data.totalRecords}  
            });
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const updateOrder = orderId => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.put('/api/orders/update_order/?orderId='+orderId);
            if(res.data){
                dispatch({
                    type : ORDER_UPDATED_SET ,
                });
                toastHandler('Order Marked as Completed!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const getOrderById = (id) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/orders/'+id);
            if(res.data){
                dispatch({
                    type : GET_ORDER_BY_ID ,
                    payload : { order:res.data.order }  
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}