import {    LOAD_CATEGORIES ,
            CATEGORY_SET_ACTION ,
            CATEGORY_RESET_ACTION ,
            GET_CATEGORY_BY_ID ,
            CATEGORY_UPDATED_SET ,
            CATEGORY_UPDATED_RESET , 
            } from '../actions/actions_types';


const initialState = {
    categories : null,
    success : false,
    action : false,
    updated : false,
    category : null
}


const categories = ( state = initialState , action ) => {
    const { type , payload } = action ; 
    switch(type){

        case LOAD_CATEGORIES:
            return {
                ...state ,
                success : true , 
                categories : payload.categories ,
            };
        
        case CATEGORY_SET_ACTION:
            return {
                ...state ,
                action : true
            };

        case CATEGORY_RESET_ACTION:
            return {
                ...state ,
                action : false,
            };

        case GET_CATEGORY_BY_ID:
            return {
                ...state ,
                action : true , 
                category : payload.category ,
            };

        case CATEGORY_UPDATED_SET:
            return {
                ...state ,
                updated : true , 
            };

        case CATEGORY_UPDATED_RESET:
            return {
                ...state ,
                updated : false , 
            };
  
        
            
        default:
            return state;

    }
}

export default categories;