import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { useParams ,useHistory } from 'react-router-dom';
import { getUserById , deleteUser } from '../../redux/actions/users';
import { USER_RESET_ACTION } from '../../redux/actions/actions_types';
import { BackButton , CustomIconButton , DeleteButton } from '../actionButtons/actionButtons'
import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';
import classes from './CommonStyle.module.css';


export default function ViewUser( props ) {
    
    const[user , setUser] = useState();
    const[loading , setLoading] = useState(true);
    const[isDeleted , setIsDeleted] = useState(false);
    
    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
        history.goBack();
    }

    // Load User data by id
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getUserById(id)); 
    },[dispatch , id]);
    
    // Set User data from redux to local state
    const usersStateObj = useSelector( rootState => rootState.usersState );
    useEffect(()=>{
      if(usersStateObj.action){
        dispatch({
          type : USER_RESET_ACTION,
        }); 
        setUser(usersStateObj.user);
        setLoading(false);
      }  
    },[usersStateObj,dispatch]);


    // ---- Delete User handler ----- 
    const deleteUserHandler = id => {
      Confirm('Are you sure to delete this User?' ,result => {
        if(result){
          setLoading(true);
          setIsDeleted(true);
          dispatch(deleteUser(id)); 
        }
      });
    }

    // ---- Check either User Delete Or Not ------
    useEffect(()=>{
      if(usersStateObj.action && isDeleted){
        dispatch({
          type : USER_RESET_ACTION
        })
        setLoading(false);
        history.goBack();
      }  
    },[usersStateObj , dispatch , history , isDeleted]);


  if(loading){
    return <Loader />
  }

  return (
    <div style={{backgroundColor:'#f7f7f7' , width:'100%' ,padding:'0% 0% 5% 0%'}}>
        <div className={classes.UserViewContainer}>
            <h1>User Detail</h1>
            <div className={classes.LoadedImage}>
                <img 
                  src={user.image ? user.image : "https://i.pravatar.cc/200?u=" + user.email }
                  alt='profile'
                />
            </div>
            <div><h3>First Name:</h3><p>{user.firstName}</p></div>
            <hr></hr>
            <div><h3>Last Name:</h3><p>{user.lastName}</p></div>
            <hr></hr>
            <div><h3>Email:</h3><p >{user.email}</p></div>
            <hr></hr>
            <div><h3>Selected Category:</h3><p>{user.category.name}</p></div>
            <hr></hr>
            <div><h3>Height:</h3><p>{user.feet+"."+user.inches}</p></div>
            <hr></hr>
            <br/>
            <br/>

            <h1>User Measurments</h1>
            {
              user.measurments ? ( <>
                                    <div><h3>Chest:</h3><p>{user.measurments.chest}</p></div>
                                    <hr></hr>
                                    <div><h3>Hips:</h3><p>{user.measurments.hips}</p></div>
                                    <hr></hr>
                                    <div><h3>Kameez( length ):</h3><p>{user.measurments.kameez}</p></div>
                                    <hr></hr>
                                    <div><h3>Shalwar/Jeans( length ):</h3><p>{user.measurments.shalwar_jeans}</p></div>
                                    <hr></hr>
                                    <div><h3>Shirt( length ):</h3><p>{user.measurments.shirt}</p></div>
                                    <hr></hr>
                                    <div><h3>Shoulder:</h3><p>{user.measurments.shoulder}</p></div>
                                    <hr></hr>
                                    <div><h3>Sleeve:</h3><p >{user.measurments.sleeve}</p></div>
                                    <hr></hr>
                                    <div><h3>waist:</h3><p>{user.measurments.waist}</p></div>
                                    <hr></hr>
                                    <br/>
                                    <br/>
                                    </>)
                                : <span >No measurments found!<br/></span>
            }
            
            <br></br>
            <br></br>
            <BackButton />
            <CustomIconButton 
                link = {`/dashboard/users/edit/${user.id}`}
                title = "Edit User"
                icon = "EditIcon"
            />
            <DeleteButton 
              title = "Delete User"
              handler = {() => deleteUserHandler(user.id)}
            />
            
          </div>
    </div>
  );
}