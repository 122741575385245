import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { useParams ,useHistory } from 'react-router-dom';
import { getSubCategoryById , deleteSubCategory } from '../../redux/actions/subCategories';
import { SUBCATEGORY_RESET_ACTION  } from '../../redux/actions/actions_types';
import { BackButton , CustomIconButton , DeleteButton } from '../actionButtons/actionButtons';
import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';
import classes from './CommonStyle.module.css';

export default function ViewSubCategory( props ) {
    
    const[subCategory , setSubCategory] = useState();
    const[loading , setLoading] = useState(true);
    const[isDeleted , setIsDeleted] = useState(false);

    // ---- Check either query params has any id or not
    const history = useHistory();
    let { id } = useParams();
    id = Number.parseInt(id);
    if (Number.isNaN(id)) {
      history.goBack();
    }

    // ---- Load subcategory by id to display 
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getSubCategoryById(id)); 
    },[dispatch , id]);
    
    // ---- Set subcategory Obj from redux to local state 
    const subCategoriesStateObj = useSelector( rootState => rootState.subCategoriesState );
    useEffect(()=>{
      if(subCategoriesStateObj.action){
        dispatch({
          type : SUBCATEGORY_RESET_ACTION
        })
        setSubCategory(subCategoriesStateObj.subCategory);
        setLoading(false);
      }  
    },[subCategoriesStateObj,dispatch]);


    // ---- Delete subCategories handler -------
    const deleteSubCategoryHandler = id => {
      Confirm('Are you sure to delete this SubCategory?' ,  result => {
        if(result){
          setLoading(true);
          setIsDeleted(true);
          dispatch(deleteSubCategory(id));
        }
      })
    }

    // ---- Check either subCategory deleted or Not -------
    useEffect(()=>{
      if(subCategoriesStateObj.action && isDeleted){
        dispatch({
          type : SUBCATEGORY_RESET_ACTION
        });
        setLoading(false);
        history.goBack();
      }
    },[subCategoriesStateObj , dispatch , history , isDeleted]);

    if(loading){
      return <Loader />
    }
    
  return (
    <div>
      <div>
        <h1 style={{marginTop:'2%'}} >{subCategory.title}</h1>
        <div className={classes.LoadedImage}>
            <img 
              src={subCategory.image}
              alt='subCategory'
            />
        </div>

        <br></br>
        <br></br>
        <BackButton />
        <CustomIconButton 
            link = {`/dashboard/subCategories/edit/${subCategory.id}`}
            title = "Edit SubCategory"
            icon = "EditIcon"
        />
        <DeleteButton 
          title = "Delete SubCategory"
          handler = {() => deleteSubCategoryHandler(subCategory.id)}
        />
      </div>
    </div>
  );
}