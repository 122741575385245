import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { Link , useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { loadOrders , updateOrder  } from '../../redux/actions/orders';
import { ORDER_UPDATED_RESET } from '../../redux/actions/actions_types';
import { CustomIconButton , MarkDoneButton , FilterButton } from '../actionButtons/actionButtons';
import Filter from '../filter/Filter';
import Loader from '../loader/Loader';
import confirm from './../confirm/Confirm';
import OrdersFilters from './OrdersFilters';

const useStyles = makeStyles( theme => ({
  table: {

    minWidth: 650,

    '& th':{

      fontWeight:"bold!important" ,
      fontSize:'1rem',
      fontFamily:'inherit',
      color: '#495057',
    },
    '& td':{
      padding:'5px 0px 5px 1.5%' ,
      fontFamily:'',
    },
    '& tr:nth-child(even)' : {
      backgroundColor:'#f8f8f8',
    },
    '& tr:hover' : {
      backgroundColor:'#f2f2f2',
    },

  },
  card : {
    display : 'flex',
    width : '60%',
    border : '.2px solid #f1f1f1',
    backgroundColor:'#f7f7f7',
    borderRadius : '5px',
    [theme.breakpoints.down('md')]: {
      width : '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width : '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width : '95%',
    },
  },
  link : {
    textDecoration : 'none' , 
    color : 'black',
    '& :hover' : {
      textDecoration : 'underline',
    },
  },
})
);

export default function Orders( props ) {

    const [allOrders , setAllOrders] = useState([]);
    const [filters , setFilters] = useState({});
    const [loading , setLoading] = useState(true);


    // ---- Filter toggle Handler
    const [filterOpen, setFilterOpen] = React.useState(false);
    const handleClose = () => {
      setFilterOpen(false);
    };
    const handleToggle = () => {
      setFilterOpen(!filterOpen);
    };

    const search = useLocation().search;

    // ---- Pagination -------
    const [totalRecords , setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const dispatch = useDispatch();
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        dispatch(loadOrders({size:rowsPerPage ,page:newPage} , filters));
    };

    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value);
        setRowsPerPage(value);
        dispatch(loadOrders({size:value ,page:page} , filters));
    };

    // ---- Load orders data to display -------
    useEffect(()=>{
      const userId = new URLSearchParams(search).get('userId');
      if(userId && !filters.hasOwnProperty('userId')){
        setFilters({...filters , 'userId':userId})
        dispatch(loadOrders({size:10 ,page:0} , {'userId':userId})); 
      }else{
        dispatch(loadOrders({size:10 ,page:0} , filters)); 
      }
    },[dispatch , filters , search ]);
    
    // ---- Load orders from reduxStore and set into component state -------
    const ordersStateObj = useSelector( rootState => rootState.ordersState );
    useEffect(()=>{
      if(ordersStateObj.success){
        setAllOrders(ordersStateObj.orders);
        setTotalRecords(ordersStateObj.totalRecords);
        setLoading(false);
      }  
    },[ordersStateObj , page , rowsPerPage]);

    // ---- Order mark as Completed Handler ----- 
    const markCompleteHandler = orderId => {
      confirm('Are you sure to mark this order as Completed?' , result => {
        if(result){
          setLoading(true);
          dispatch(updateOrder(orderId));
        }
      });
    }

    // ---- Check either Order updated or Not ----
    useEffect(()=>{
    if(ordersStateObj.updated){
      dispatch({
        type : ORDER_UPDATED_RESET
      })
      dispatch(loadOrders({size:10 ,page:0} , filters)); 
        setLoading(false);
    }
    },[ordersStateObj,dispatch , filters]);


    // ---- Filters Apply Handler ---- 
    const filterApplyHandler = formData => {
      const updatedFilters = {...filters ,...formData };
      handleClose();
      setFilters(updatedFilters); 
    } 


  const classes = useStyles();

  if(loading){
    return <Loader />
  }

  return (
    <>

        {/* Filters Start */}
          <Filter filterOpen={filterOpen} closeHandler={handleClose} >
              <OrdersFilters filterApplyHandler={filterApplyHandler} />
          </Filter>
        {/* Filters End */}


        
        <div style={{display:'flex' ,justifyContent:'center' ,marginBottom:'2%'}}>
            <h2 style={{marginTop:'1.8%'}}>Orders</h2>
            <FilterButton handler={handleToggle} />
        </div>

        {!allOrders.length 
            ?   <strong>No Record Found</strong>
            :   <TableContainer style={{width:'90%',margin:'auto',boxShadow:' 1px 1px 16px rgba(0,0,0,.2)' }} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Order Id</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Completed</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allOrders.map((order , index) => (
                                <TableRow key={index}>
                                    <TableCell scope="row">{ "SK00" + order.id}</TableCell>
                                    <TableCell align="left" >
                                      <Link to={`users/view/${order.user.id}`} className={classes.link} >
                                        <p>{order.user.firstName + ' ' + order.user.lastName}</p>
                                      </Link>
                                    </TableCell>  
                                    
                                    <TableCell align="left">{order.processed ? 'Yes' : 'No' }</TableCell>
                                    <TableCell align="left">{order.total}</TableCell>
                                    <TableCell align="center">
                                      <CustomIconButton 
                                        link = {`orders/view/${order.id}`}
                                        title = "View Order"
                                        icon = "VisibilityIcon"
                                      /> 
                                      <MarkDoneButton 
                                          title = "Mark as Completed"
                                          handler = {() => markCompleteHandler(order.id)}
                                          disabled = {order.processed ? true : false }
                                      />
                                      </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={totalRecords}
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
            </TableContainer>
        }
    </>
  );
}
