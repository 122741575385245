import React from 'react';
import { Route , Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GuestRoute = props => {
    
    const {path , Component} = props;
    const authStateObj = useSelector( rootState => rootState.authState );
    const isAuthenticated = authStateObj.isAuthenticated;

    if(!isAuthenticated){
        return <Route path={path} component={Component} />
    }
    return <Redirect to="/dashboard" />
   
}


export default GuestRoute;