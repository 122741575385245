import React , { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { loadClothes , deleteCloth  } from '../../redux/actions/clothes';
import { CustomIconButton , DeleteButton} from '../actionButtons/actionButtons';

import Loader from '../loader/Loader';
import Confirm from '../confirm/Confirm';

const useStyles = makeStyles({
  container: {
    display:"flex",
    justifyContent:'center',
    flexWrap:"wrap",
    width: '80%',
    margin : 'auto'
  },
  root: {
    padding : '1%',
    backgroundColor: 'white',
    border: '1px solid #ede9e8',
    borderRadius:'5px',
    flex: '0 0 auto',
    margin : '1%',
    transition: '0.3s',
    "&:hover": {    
      boxShadow: '0 1.5rem 2rem rgba(0,0,0,.2)',
      cursor: 'pointer'
    },
    // width : '22.5%',
    boxSizing : 'border-box'
  },
  figure:{
    width: '200px',
    height: '200px',
    margin: '0',
    padding: '0',
    background: '#fff',
    overflow: 'hidden',
  },
  cardImg:{
    borderBottom: '1px solid grey',
    borderRadius:'5px 5px',
    width : '200px',
    height : '200px',
    objectFit : 'cover',
    webkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)',
    webkitTransition: '.3s ease-in-out',
    transition: '.3s ease-in-out',
    "&:hover": {    
      webkitFilter: 'grayscale(0)',
      filter: 'grayscale(0)',
    },
  },
  pTag:{
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

});

export default function Clothes() {
  const [allClothes , setAllClothes] = useState([]);
  const [loading , setLoading] = useState(true);

  const classes = useStyles();

      // ---- Load clothes data to display -------
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(loadClothes()); 
        setLoading(false);
    },[dispatch]);
    
    // ---- Load clothes from reduxStore and set into component state -------
    const clothesStateObj = useSelector( rootState => rootState.clothesState );
    useEffect(()=>{
      if(clothesStateObj.success){
        setAllClothes(clothesStateObj.clothes);
      }  
    },[clothesStateObj]);

    // ---- Delete cloth handler -------
    const deleteClothHandler = id => {
      Confirm('Are you sure to delete this Cloth?' , result => {
        if(result){
          setLoading(true);
          dispatch(deleteCloth(id));
        }
      });
    }

    // ---- Check either cloth deleted or Not ----
    useEffect(()=>{
      if(clothesStateObj.action){
          dispatch(loadClothes()); 
          setLoading(false);
      }
    },[clothesStateObj,dispatch]);

    if(loading){
      return <Loader />
    }

    return (
      <>  
        <div style={{display:'flex' ,justifyContent:'center' ,marginBottom:'2%'}}>
              <h1 style={{marginTop:'1.2%'}}>Clothes</h1>
              <CustomIconButton 
                link = {`clothes/create`}
                title = "Create Cloth"
                icon = "AddCircleOutlinedIcon"
              />
        </div>
          { !(allClothes.length)   ? (<h2> No Record Found! </h2>) 
                          : <div className={classes.container} >
                              {allClothes.map( cloth => {
                                return (
                                    <div key={cloth.id} className={classes.root}>
                                      <figure className={classes.figure}>
                                        <img src={cloth.images[0]} alt="Avatar" className={classes.cardImg} />
                                      </figure>
                                      <div style={{textAlign:'left',padding:'5% 5% 0% 5%' }}>
                                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                          <h3>{cloth.title}</h3> <h3>PKR:{cloth.price}</h3>
                                        </div>
                                        <h4 className={classes.pTag}>{cloth.properties.map(prop => prop + ' ')}</h4> 

                                        <hr style={{width:'100%' , margin:'auto'}} />
                                        <div style={{display:'flex' , justifyContent:'space-between'}} >
                                          <div>
                                            <CustomIconButton 
                                              link = {`clothes/view/${cloth.id}`}
                                              title = "View Cloth"
                                              icon = "VisibilityIcon"
                                            />
                                            <CustomIconButton 
                                              link = {`clothes/edit/${cloth.id}`}
                                              title = "Edit Cloth"
                                              icon = "EditIcon"
                                            />
                                          </div>
                                          <div >
                                            <DeleteButton 
                                              title = "Delete Cloth"
                                              handler = {() => deleteClothHandler(cloth.id)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                )
                              })}
                            </div>
                      
                          
          }
      </>
    );
    
     
      
   
}
