import React from 'react';
import { Switch , Route, Redirect } from 'react-router-dom';

import DashboardContent from '../components/dashboardContent/DashboardContent';

// Categories components
import Categories from '../components/categories/Categories';
import CreateCategory from '../components/categories/Create';
import EditCategory from '../components/categories/Edit';
import ViewCategory from '../components/categories/View';

// SubCategories components
import SubCategories from '../components/subcategories/SubCategories';
import CreateSubCategory from '../components/subcategories/Create';
import EditSubCategory from '../components/subcategories/Edit';
import ViewSubCategory from '../components/subcategories/View';

// clothes components
import Clothes from '../components/clothes/Clothes';
import CreateCloth from '../components/clothes/Create';
import EditCloth from '../components/clothes/Edit';
import ViewCloth from '../components/clothes/View';

// users components
import Users from '../components/users/Users';
import ViewUser from '../components/users/View';
import EditUser from '../components/users/Edit';

// orders components
import Orders from '../components/orders/Orders';
import ViewOrder from '../components/orders/View';




const DashboardRouter = props => {
    const basePath = '/dashboard';
    return (
        <Switch>
            <Route exact path={basePath} component={DashboardContent} /> 

            {/* Categoreis Routes */}
            <Route exact path={`${basePath}/categories/create`} component={CreateCategory}    /> 
            <Route exact path={`${basePath}/categories/edit/:id`} component={EditCategory}    /> 
            <Route exact path={`${basePath}/categories/view/:id`} component={ViewCategory}    /> 
            <Route exact path={`${basePath}/categories`} component={Categories}    /> 

            {/* SubCategories Routes */}
            <Route exact path={`${basePath}/subCategories/create`} component={CreateSubCategory}    /> 
            <Route exact path={`${basePath}/subCategories/edit/:id`} component={EditSubCategory}    /> 
            <Route exact path={`${basePath}/subCategories/view/:id`} component={ViewSubCategory}    />
            <Route exact path={`${basePath}/subCategories`} component={SubCategories}    /> 

            {/* clothes Routes */}
            <Route exact path={`${basePath}/clothes/create`} component={CreateCloth}   /> 
            <Route exact path={`${basePath}/clothes/edit/:id`} component={EditCloth}   /> 
            <Route exact path={`${basePath}/clothes/view/:id`} component={ViewCloth}   /> 
            <Route exact path={`${basePath}/clothes`} component={Clothes}   /> 

            {/* users Routes */}
            <Route exact path={`${basePath}/users/edit/:id`} component={EditUser}   /> 
            <Route exact path={`${basePath}/users/view/:id`} component={ViewUser}   /> 
            <Route exact path={`${basePath}/users`} component={Users}   /> 

            {/* orders Routes */}
            <Route exact path={`${basePath}/orders/view/:id`} component={ViewOrder}   /> 
            <Route exact path={`${basePath}/orders`} component={Orders}   /> 


            <Redirect to={basePath} />
        </Switch>
    );
}

export default DashboardRouter;