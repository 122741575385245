import React,{ useEffect } from 'react';
import { Redirect,Switch } from 'react-router';
import { useDispatch } from 'react-redux';

import Login from '../components/auth/Login/Login';
import VerifyEmail from '../components/auth/forgetPassword/VerifyEmail';
import VerifyOTP from '../components/auth/forgetPassword/VerifyOTP';
import ChangePassword from '../components/auth/forgetPassword/ChangePassword';

import Logout from '../components/auth/Logout/Logout';
import DashboardContainer from '../containers/dashboardContainer/Dashboard';
import PrivateRoute from './RouterMiddlewares/PrivateRoute';
import GuestRoute from './RouterMiddlewares/GuestRoute';
import { loadUser } from '../redux/actions/auth';


const MainRouter = props => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(loadUser());
    },[dispatch]);
    return (
        <Switch>

            <GuestRoute  path="/login" Component={Login} />
            <GuestRoute  path="/verifyEmail" Component={VerifyEmail} />
            <GuestRoute  path="/verifyOTP" Component={VerifyOTP} />
            <GuestRoute  path="/changePassword" Component={ChangePassword} />


            <PrivateRoute  path="/dashboard" Component={DashboardContainer} />
            <PrivateRoute  path="/logout" Component={Logout} />

            <Redirect from="/" to='/login' exact component={Login} />
            
        </Switch>
    );
}

export default MainRouter;