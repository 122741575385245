const getItemList = path => {
    return [
        { 
            name:'Products' ,
            icon:'fab fa-product-hunt', 
            submenu : [
                {   
                    name:'Categoreis' ,
                    icon:'fas fa-list-ul', 
                    link:`${path}/categories` 
                } ,
                { 
                    name:'SubCategories' ,
                    icon:'far fa-list-alt', 
                    link:`${path}/subCategories` 
                },
                { 
                    name:'Clothes' ,
                    icon:'fas fa-tshirt', 
                    link:`${path}/clothes` 
                }
            ] 
        },
        { 
            name:'Users' ,
            icon:'fas fa-users',  
            link:`${path}/users` ,
            submenu : []
        },
        { 
            name:'Orders' ,
            icon:'far fa-list-alt',  
            link:`${path}/orders` ,
            submenu : []
        },
    ]
}

export default getItemList;