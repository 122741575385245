import { LOAD_SUBCATEGORIES_AND_PROPERTIES ,LOAD_DASHBOARD_CONTENTS , ADMIN_STATE_RESET_ACTION } from '../actions/actions_types';


const initialState = {
    subCategories : null,
    properties : null,
    success : false,
    dashboardContent:null
}


const adminCommon = ( state = initialState , action ) => {
    const { type , payload } = action ; 
    switch(type){

        case LOAD_SUBCATEGORIES_AND_PROPERTIES:
            return {
                ...state , 
                success : true ,
                properties : payload.properties , 
                subCategories : payload.subCategories , 
            };

        case LOAD_DASHBOARD_CONTENTS:
            return {
                    ...state , 
                    success : true ,
                    dashboardContent : payload.dashboardContent , 
                  
            };

        case ADMIN_STATE_RESET_ACTION:
            return {
                ...state , 
                success : false ,
              
            };

        default:
            return state;

    }
}

export default adminCommon;