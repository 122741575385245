import axios from '../../services/axios';
import { LOAD_CLOTHES , CLOTH_SET_ACTION , GET_CLOTH_BY_ID , CLOTH_UPDATED_SET } from './actions_types';
import setAuthToken from './../../utils/setAuthToken';
import toastHandler from '../../components/ToastNotification/toastHandler';


export const loadClothes = (size,page) => async dispatch => {
    let apiURL = '/api/clothes/get_clothes';
    if(size){
        apiURL = `api/clothes/get_clothes/?size=${size}&page=${page}`;
    }
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get(apiURL);
            dispatch({
                type : LOAD_CLOTHES ,
                payload : {clothes:res.data.clothes , totalRecords:res.data.totalRecords}  
            });
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}



export const createCloth = (formData) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.post('/api/clothes/create_cloth', formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            if(res.data){
                dispatch({
                    type : CLOTH_SET_ACTION ,
                });
                toastHandler('Cloth created successfully!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}



export const getClothById = (id) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/clothes/'+id);
            if(res.data){
                dispatch({
                    type : GET_CLOTH_BY_ID ,
                    payload : { cloth:res.data.cloth }  
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const updateCloth = (formData ,  clothId) => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.put('/api/clothes/update_cloth/?clothId='+clothId, formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            if(res.data){
                dispatch({
                    type : CLOTH_UPDATED_SET ,
                });
                toastHandler('Cloth Updated successfully!' , 'success');
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
            }
            console.log(error);
        }   
    }
}


export const deleteCloth = clothId => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.delete('/api/clothes/delete_cloth/?clothId='+clothId);
            if(res){
                toastHandler('Cloth deleted successfully!' , 'success');
                dispatch({
                    type : CLOTH_SET_ACTION
                });
            }
        } catch (error) {
            if(error?.response?.data?.error){
                toastHandler(error.response.data.error , 'error');
                dispatch({
                    type : CLOTH_SET_ACTION
                });
            }
            console.log(error);
        }   
    }
}