import { toast } from 'react-toastify';


const toastHandler = (msg , type) => {
    const setting = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    switch(type){
        case 'default':
            toast(msg,setting);
            break;

        case 'info':
            toast.info(msg,setting);
            break;
        
        case 'success':
            toast.info(msg,setting);
            break;

        case 'warning':
            toast.warn(msg,setting);
            break;

        case 'error':
            toast.error(msg,setting)
            break;

        default:
            break;
    }
}

export default toastHandler;