import axios from '../../services/axios';
import { LOAD_SUBCATEGORIES_AND_PROPERTIES, LOAD_DASHBOARD_CONTENTS } from './actions_types';
import setAuthToken from './../../utils/setAuthToken';
import toastHandler from '../../components/ToastNotification/toastHandler';


export const loadCategoriesAndProperties = (size, page) => async dispatch => {
    if (localStorage.access_token) {
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/admin/get_subcategories_and_properties');
            dispatch({
                type: LOAD_SUBCATEGORIES_AND_PROPERTIES,
                payload: { subCategories: res.data.subCategories, properties: res.data.properties }
            });
        } catch (error) {
            if (error?.response?.data?.error) {
                toastHandler(error.response.data.error, 'error');
            }
            console.log(error);
        }
    }
}

export const loadDashboardContents = () => async dispatch => {
    if (localStorage.access_token) {
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/admin/get_dashboard_contents');
            dispatch({
                type: LOAD_DASHBOARD_CONTENTS,
                payload: {
                    dashboardContent:res.data
                }
            })
           
        } catch (error) {
            console.log(error);
        }
    }
}


