import axios from '../../services/axios';
import { LOAD_USER , LOGIN_USER , LOGOUT_USER , SET_OTP_SENT , SET_OTP_VERIFIED ,SET_PASSWORD_CHANGED  } from './actions_types';
import setAuthToken from './../../utils/setAuthToken';
import toastHandler from '../../components/ToastNotification/toastHandler';


export const loginUser = userFormData => async dispatch => {
    try {
        const res = await axios.post('/api/auth/adminlogin' , userFormData );
        localStorage.setItem('access_token',res.data.tokens.access.token);  
        localStorage.setItem('refresh_token',res.data.tokens.refresh.token);  
        dispatch({
            type : LOGIN_USER ,
            payload : res.data.user 
        });
    } catch (error) {
        if(error?.response?.data?.error){
            toastHandler(error.response.data.error , 'error');
        }
        console.log(error);
    }
}



export const loadUser = () => async dispatch => {
    if(localStorage.access_token){
        setAuthToken(localStorage.access_token);
        try {
            const res = await axios.get('/api/auth/me');
            dispatch({
                type : LOAD_USER , 
                payload : res.data.user 
            }) 
        } catch (error) {
            console.log(error);
        }
    }
}


export const verifyEmail = email => async dispatch => {
    try {
        const res = await axios.post('/api/auth/verifyEmail' , {email} );
        dispatch({
            type : SET_OTP_SENT ,
            payload : {email : email}

        });
        if(res.data.message){
            toastHandler(res.data.message , 'success');
        }
    } catch (error) {
        if(error?.response?.data?.error){
            toastHandler(error.response.data.error , 'error');
        }
        console.log(error);
    }
}

export const verifyOTP = formData => async dispatch => {
    try {
        const res = await axios.post('/api/auth/verifyOTP' , formData );
        dispatch({
            type : SET_OTP_VERIFIED ,
        });
        if(res.data.message){
            toastHandler(res.data.message , 'success');
        }
    } catch (error) {
        if(error?.response?.data?.error){
            toastHandler(error.response.data.error , 'error');
        }
        console.log(error);
    }
}

export const changePassword = formData => async dispatch => {
    try {
        const res = await axios.post('/api/auth/changePassword' , formData );
        dispatch({
            type : SET_PASSWORD_CHANGED ,
        });
        if(res.data.user){
            toastHandler('Password reset successfully!', 'success');
        }
    } catch (error) {
        if(error?.response?.data?.error){
            toastHandler(error.response.data.error , 'error');
        }
        console.log(error);
    }
}


export const logout = () => async dispatch => {
    dispatch({
        type : LOGOUT_USER , 
        LOGOUT_USER
    }) 
      
}
