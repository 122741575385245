import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classes from './CustomMenuItem.module.css';

export default function CustomMenuItem( props ) {
  
  
  const[dropdownShow , setDropDownShow] = useState(false);
  const[dropdownContentClasses , setDropdownContentClasses] = useState(classes.dropdownContent+' ' + classes.hide);

  // -------- get user data
  const authStateObj = useSelector( rootState => rootState.authState );
  const user = authStateObj.userObj;

  const avatarSrc = user.image ? user.image : "https://i.pravatar.cc/50?u=" + user.email; 

  const setClassesHandler = show => {
    setDropDownShow(show);
    if(show){
      setDropdownContentClasses(classes.dropdownContent+' ' + classes.show)
    }else{
      setDropdownContentClasses(classes.dropdownContent+' ' + classes.hide)
    }
  }
  
  useEffect(() => {
    
  },[dropdownShow])
  
  return (
    
    <div className={classes.dropdown} onClick={() => setClassesHandler(!dropdownShow)}>
      <div className={classes.menuButton} onClick={() => setClassesHandler(!dropdownShow)} >
        <img src={avatarSrc} alt="avt" style={{width:'45px' ,height:'45px', borderRadius:'50%'}}  />
      </div> 
      <div className={dropdownContentClasses}>
        <div className={classes.card}>
          <img className={classes.img} src={avatarSrc} alt="avt" />
              <div >
                <h3>{user.firstName + ' ' + user.lastName}</h3> 
              </div>                                    
        </div>
        <div className={classes.linksDiv} >
          <Link to={'/dashboard/users/edit/'+user.id}  onClick={() => setClassesHandler(!dropdownShow)}  >Profile</Link>
          <hr></hr>
          <Link to='/logout' onClick={() => setClassesHandler(!dropdownShow)}  >Logout</Link>
          <hr></hr>

        </div>
      </div>
    </div> 
     
  );
}
